import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalNav from "../../Navbar/VerticalNav";
import CredentialIndex from "../../Credentials/CredentialIndex";
import ProfileMenu from "../../Profile/ProfileMenu";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

function Credentials({isAuthenticated}) {
  
if(!isAuthenticated){
  return <Navigate to="/login" />;
}
  return (
     <>
        
        <Grid container sx={{ height: "100vh",backgroundColor:"#F7F8FA" }}>
          <Grid item md={2} sx={{height:"100%" }}>
            <VerticalNav lineState={5} />
          </Grid>
          <Grid item md={10} sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}>
            <ProfileMenu />
           <CredentialIndex />
          </Grid>
        </Grid>
     

     </>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Credentials)