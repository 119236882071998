import React, { useState } from "react";
import Input from "@mui/material/Input";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";
import profileImage from "../../assets/profile.jpg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { connect } from "react-redux";
import { FormHelperText } from "@mui/material";
import axios from 'axios';
import { setPass,headers } from "../helpers/helpers";
import FormControl from '@mui/material/FormControl';


const timezones = ['Africa/Abidjan',
'Africa/Accra',
'Africa/Addis_Ababa',
'Africa/Algiers',
'Africa/Asmara',
'Africa/Asmera',
'Africa/Bamako',
'Africa/Bangui',
'Africa/Banjul',
'Africa/Bissau',
'Africa/Blantyre',
'Africa/Brazzaville',
'Africa/Bujumbura',
'Africa/Cairo',
'Africa/Casablanca',
'Africa/Ceuta',
'Africa/Conakry',
'Africa/Dakar',
'Africa/Dar_es_Salaam',
'Africa/Djibouti',
'Africa/Douala',
'Africa/El_Aaiun',
'Africa/Freetown',
'Africa/Gaborone',
'Africa/Harare',
'Africa/Johannesburg',
'Africa/Juba',
'Africa/Kampala',
'Africa/Khartoum',
'Africa/Kigali',
'Africa/Kinshasa',
'Africa/Lagos',
'Africa/Libreville',
'Africa/Lome',
'Africa/Luanda',
'Africa/Lubumbashi',
'Africa/Lusaka',
'Africa/Malabo',
'Africa/Maputo',
'Africa/Maseru',
'Africa/Mbabane',
'Africa/Mogadishu',
'Africa/Monrovia',
'Africa/Nairobi',
'Africa/Ndjamena',
'Africa/Niamey',
'Africa/Nouakchott',
'Africa/Ouagadougou',
'Africa/Porto-Novo',
'Africa/Sao_Tome',
'Africa/Timbuktu',
'Africa/Tripoli',
'Africa/Tunis',
'Africa/Windhoek',
'America/Adak',
'America/Anchorage',
'America/Anguilla',
'America/Antigua',
'America/Araguaina',
'America/Argentina/Buenos_Aires',
'America/Argentina/Catamarca',
'America/Argentina/ComodRivadavia',
'America/Argentina/Cordoba',
'America/Argentina/Jujuy',
'America/Argentina/La_Rioja',
'America/Argentina/Mendoza',
'America/Argentina/Rio_Gallegos',
'America/Argentina/Salta',
'America/Argentina/San_Juan',
'America/Argentina/San_Luis',
'America/Argentina/Tucuman',
'America/Argentina/Ushuaia',
'America/Aruba',
'America/Asuncion',
'America/Atikokan',
'America/Atka',
'America/Bahia',
'America/Bahia_Banderas',
'America/Barbados',
'America/Belem',
'America/Belize',
'America/Blanc-Sablon',
'America/Boa_Vista',
'America/Bogota',
'America/Boise',
'America/Buenos_Aires',
'America/Cambridge_Bay',
'America/Campo_Grande',
'America/Cancun',
'America/Caracas',
'America/Catamarca',
'America/Cayenne',
'America/Cayman',
'America/Chicago',
'America/Chihuahua',
'America/Coral_Harbour',
'America/Cordoba',
'America/Costa_Rica',
'America/Creston',
'America/Cuiaba',
'America/Curacao',
'America/Danmarkshavn',
'America/Dawson',
'America/Dawson_Creek',
'America/Denver',
'America/Detroit',
'America/Dominica',
'America/Edmonton',
'America/Eirunepe',
'America/El_Salvador',
'America/Ensenada',
'America/Fort_Nelson',
'America/Fort_Wayne',
'America/Fortaleza',
'America/Glace_Bay',
'America/Godthab',
'America/Goose_Bay',
'America/Grand_Turk',
'America/Grenada',
'America/Guadeloupe',
'America/Guatemala',
'America/Guayaquil',
'America/Guyana',
'America/Halifax',
'America/Havana',
'America/Hermosillo',
'America/Indiana/Indianapolis',
'America/Indiana/Knox',
'America/Indiana/Marengo',
'America/Indiana/Petersburg',
'America/Indiana/Tell_City',
'America/Indiana/Vevay',
'America/Indiana/Vincennes',
'America/Indiana/Winamac',
'America/Indianapolis',
'America/Inuvik',
'America/Iqaluit',
'America/Jamaica',
'America/Jujuy',
'America/Juneau',
'America/Kentucky/Louisville',
'America/Kentucky/Monticello',
'America/Knox_IN',
'America/Kralendijk',
'America/La_Paz',
'America/Lima',
'America/Los_Angeles',
'America/Louisville',
'America/Lower_Princes',
'America/Maceio',
'America/Managua',
'America/Manaus',
'America/Marigot',
'America/Martinique',
'America/Matamoros',
'America/Mazatlan',
'America/Mendoza',
'America/Menominee',
'America/Merida',
'America/Metlakatla',
'America/Mexico_City',
'America/Miquelon',
'America/Moncton',
'America/Monterrey',
'America/Montevideo',
'America/Montreal',
'America/Montserrat',
'America/Nassau',
'America/New_York',
'America/Nipigon',
'America/Nome',
'America/Noronha',
'America/North_Dakota/Beulah',
'America/North_Dakota/Center',
'America/North_Dakota/New_Salem',
'America/Nuuk',
'America/Ojinaga',
'America/Panama',
'America/Pangnirtung',
'America/Paramaribo',
'America/Phoenix',
'America/Port-au-Prince',
'America/Port_of_Spain',
'America/Porto_Acre',
'America/Porto_Velho',
'America/Puerto_Rico',
'America/Punta_Arenas',
'America/Rainy_River',
'America/Rankin_Inlet',
'America/Recife',
'America/Regina',
'America/Resolute',
'America/Rio_Branco',
'America/Rosario',
'America/Santa_Isabel',
'America/Santarem',
'America/Santiago',
'America/Santo_Domingo',
'America/Sao_Paulo',
'America/Scoresbysund',
'America/Shiprock',
'America/Sitka',
'America/St_Barthelemy',
'America/St_Johns',
'America/St_Kitts',
'America/St_Lucia',
'America/St_Thomas',
'America/St_Vincent',
'America/Swift_Current',
'America/Tegucigalpa',
'America/Thule',
'America/Thunder_Bay',
'America/Tijuana',
'America/Toronto',
'America/Tortola',
'America/Vancouver',
'America/Virgin',
'America/Whitehorse',
'America/Winnipeg',
'America/Yakutat',
'America/Yellowknife',
'Antarctica/Casey',
'Antarctica/Davis',
'Antarctica/DumontDUrville',
'Antarctica/Macquarie',
'Antarctica/Mawson',
'Antarctica/McMurdo',
'Antarctica/Palmer',
'Antarctica/Rothera',
'Antarctica/South_Pole',
'Antarctica/Syowa',
'Antarctica/Troll',
'Antarctica/Vostok',
'Arctic/Longyearbyen',
'Asia/Aden',
'Asia/Almaty',
'Asia/Amman',
'Asia/Anadyr',
'Asia/Aqtau',
'Asia/Aqtobe',
'Asia/Ashgabat',
'Asia/Ashkhabad',
'Asia/Atyrau',
'Asia/Baghdad',
'Asia/Bahrain',
'Asia/Baku',
'Asia/Bangkok',
'Asia/Barnaul',
'Asia/Beirut',
'Asia/Bishkek',
'Asia/Brunei',
'Asia/Calcutta',
'Asia/Chita',
'Asia/Choibalsan',
'Asia/Chongqing',
'Asia/Chungking',
'Asia/Colombo',
'Asia/Dacca',
'Asia/Damascus',
'Asia/Dhaka',
'Asia/Dili',
'Asia/Dubai',
'Asia/Dushanbe',
'Asia/Famagusta',
'Asia/Gaza',
'Asia/Harbin',
'Asia/Hebron',
'Asia/Ho_Chi_Minh',
'Asia/Hong_Kong',
'Asia/Hovd',
'Asia/Irkutsk',
'Asia/Istanbul',
'Asia/Jakarta',
'Asia/Jayapura',
'Asia/Jerusalem',
'Asia/Kabul',
'Asia/Kamchatka',
'Asia/Karachi',
'Asia/Kashgar',
'Asia/Kathmandu',
'Asia/Katmandu',
'Asia/Khandyga',
'Asia/Kolkata',
'Asia/Krasnoyarsk',
'Asia/Kuala_Lumpur',
'Asia/Kuching',
'Asia/Kuwait',
'Asia/Macao',
'Asia/Macau',
'Asia/Magadan',
'Asia/Makassar',
'Asia/Manila',
'Asia/Muscat',
'Asia/Nicosia',
'Asia/Novokuznetsk',
'Asia/Novosibirsk',
'Asia/Omsk',
'Asia/Oral',
'Asia/Phnom_Penh',
'Asia/Pontianak',
'Asia/Pyongyang',
'Asia/Qatar',
'Asia/Qostanay',
'Asia/Qyzylorda',
'Asia/Rangoon',
'Asia/Riyadh',
'Asia/Saigon',
'Asia/Sakhalin',
'Asia/Samarkand',
'Asia/Seoul',
'Asia/Shanghai',
'Asia/Singapore',
'Asia/Srednekolymsk',
'Asia/Taipei',
'Asia/Tashkent',
'Asia/Tbilisi',
'Asia/Tehran',
'Asia/Tel_Aviv',
'Asia/Thimbu',
'Asia/Thimphu',
'Asia/Tokyo',
'Asia/Tomsk',
'Asia/Ujung_Pandang',
'Asia/Ulaanbaatar',
'Asia/Ulan_Bator',
'Asia/Urumqi',
'Asia/Ust-Nera',
'Asia/Vientiane',
'Asia/Vladivostok',
'Asia/Yakutsk',
'Asia/Yangon',
'Asia/Yekaterinburg',
'Asia/Yerevan',
'Atlantic/Azores',
'Atlantic/Bermuda',
'Atlantic/Canary',
'Atlantic/Cape_Verde',
'Atlantic/Faeroe',
'Atlantic/Faroe',
'Atlantic/Jan_Mayen',
'Atlantic/Madeira',
'Atlantic/Reykjavik',
'Atlantic/South_Georgia',
'Atlantic/St_Helena',
'Atlantic/Stanley',
'Australia/ACT',
'Australia/Adelaide',
'Australia/Brisbane',
'Australia/Broken_Hill',
'Australia/Canberra',
'Australia/Currie',
'Australia/Darwin',
'Australia/Eucla',
'Australia/Hobart',
'Australia/LHI',
'Australia/Lindeman',
'Australia/Lord_Howe',
'Australia/Melbourne',
'Australia/NSW',
'Australia/North',
'Australia/Perth',
'Australia/Queensland',
'Australia/South',
'Australia/Sydney',
'Australia/Tasmania',
'Australia/Victoria',
'Australia/West',
'Australia/Yancowinna',
'Brazil/Acre',
'Brazil/DeNoronha',
'Brazil/East',
'Brazil/West',
'CET',
'CST6CDT',
'Canada/Atlantic',
'Canada/Central',
'Canada/Eastern',
'Canada/Mountain',
'Canada/Newfoundland',
'Canada/Pacific',
'Canada/Saskatchewan',
'Canada/Yukon',
'Chile/Continental',
'Chile/EasterIsland',
'Cuba',
'EET',
'EST',
'EST5EDT',
'Egypt',
'Eire',
'Etc/GMT',
'Etc/GMT+0',
'Etc/GMT+1',
'Etc/GMT+10',
'Etc/GMT+11',
'Etc/GMT+12',
'Etc/GMT+2',
'Etc/GMT+3',
'Etc/GMT+4',
'Etc/GMT+5',
'Etc/GMT+6',
'Etc/GMT+7',
'Etc/GMT+8',
'Etc/GMT+9',
'Etc/GMT-0',
'Etc/GMT-1',
'Etc/GMT-10',
'Etc/GMT-11',
'Etc/GMT-12',
'Etc/GMT-13',
'Etc/GMT-14',
'Etc/GMT-2',
'Etc/GMT-3',
'Etc/GMT-4',
'Etc/GMT-5',
'Etc/GMT-6',
'Etc/GMT-7',
'Etc/GMT-8',
'Etc/GMT-9',
'Etc/GMT0',
'Etc/Greenwich',
'Etc/UCT',
'Etc/UTC',
'Etc/Universal',
'Etc/Zulu',
'Europe/Amsterdam',
'Europe/Andorra',
'Europe/Astrakhan',
'Europe/Athens',
'Europe/Belfast',
'Europe/Belgrade',
'Europe/Berlin',
'Europe/Bratislava',
'Europe/Brussels',
'Europe/Bucharest',
'Europe/Budapest',
'Europe/Busingen',
'Europe/Chisinau',
'Europe/Copenhagen',
'Europe/Dublin',
'Europe/Gibraltar',
'Europe/Guernsey',
'Europe/Helsinki',
'Europe/Isle_of_Man',
'Europe/Istanbul',
'Europe/Jersey',
'Europe/Kaliningrad',
'Europe/Kiev',
'Europe/Kirov',
'Europe/Kyiv',
'Europe/Lisbon',
'Europe/Ljubljana',
'Europe/London',
'Europe/Luxembourg',
'Europe/Madrid',
'Europe/Malta',
'Europe/Mariehamn',
'Europe/Minsk',
'Europe/Monaco',
'Europe/Moscow',
'Europe/Nicosia',
'Europe/Oslo',
'Europe/Paris',
'Europe/Podgorica',
'Europe/Prague',
'Europe/Riga',
'Europe/Rome',
'Europe/Samara',
'Europe/San_Marino',
'Europe/Sarajevo',
'Europe/Saratov',
'Europe/Simferopol',
'Europe/Skopje',
'Europe/Sofia',
'Europe/Stockholm',
'Europe/Tallinn',
'Europe/Tirane',
'Europe/Tiraspol',
'Europe/Ulyanovsk',
'Europe/Uzhgorod',
'Europe/Vaduz',
'Europe/Vatican',
'Europe/Vienna',
'Europe/Vilnius',
'Europe/Volgograd',
'Europe/Warsaw',
'Europe/Zagreb',
'Europe/Zaporozhye',
'Europe/Zurich',
'GB',
'GB-Eire',
'GMT',
'GMT+0',
'GMT-0',
'GMT0',
'Greenwich',
'HST',
'Hongkong',
'Iceland',
'Indian/Antananarivo',
'Indian/Chagos',
'Indian/Christmas',
'Indian/Cocos',
'Indian/Comoro',
'Indian/Kerguelen',
'Indian/Mahe',
'Indian/Maldives',
'Indian/Mauritius',
'Indian/Mayotte',
'Indian/Reunion',
'Iran',
'Israel',
'Jamaica',
'Japan',
'Kwajalein',
'Libya',
'MET',
'MST',
'MST7MDT',
'Mexico/BajaNorte',
'Mexico/BajaSur',
'Mexico/General',
'NZ',
'NZ-CHAT',
'Navajo',
'PRC',
'PST8PDT',
'Pacific/Apia',
'Pacific/Auckland',
'Pacific/Bougainville',
'Pacific/Chatham',
'Pacific/Chuuk',
'Pacific/Easter',
'Pacific/Efate',
'Pacific/Enderbury',
'Pacific/Fakaofo',
'Pacific/Fiji',
'Pacific/Funafuti',
'Pacific/Galapagos',
'Pacific/Gambier',
'Pacific/Guadalcanal',
'Pacific/Guam',
'Pacific/Honolulu',
'Pacific/Johnston',
'Pacific/Kanton',
'Pacific/Kiritimati',
'Pacific/Kosrae',
'Pacific/Kwajalein',
'Pacific/Majuro',
'Pacific/Marquesas',
'Pacific/Midway',
'Pacific/Nauru',
'Pacific/Niue',
'Pacific/Norfolk',
'Pacific/Noumea',
'Pacific/Pago_Pago',
'Pacific/Palau',
'Pacific/Pitcairn',
'Pacific/Pohnpei',
'Pacific/Ponape',
'Pacific/Port_Moresby',
'Pacific/Rarotonga',
'Pacific/Saipan',
'Pacific/Samoa',
'Pacific/Tahiti',
'Pacific/Tarawa',
'Pacific/Tongatapu',
'Pacific/Truk',
'Pacific/Wake',
'Pacific/Wallis',
'Pacific/Yap',
'Poland',
'Portugal',
'ROC',
'ROK',
'Singapore',
'Turkey',
'UCT',
'US/Alaska',
'US/Aleutian',
'US/Arizona',
'US/Central',
'US/East-Indiana',
'US/Eastern',
'US/Hawaii',
'US/Indiana-Starke',
'US/Michigan',
'US/Mountain',
'US/Pacific',
'US/Samoa',
'UTC',
'Universal',
'W-SU',
'WET',
'Zulu']


function ProfileIndex({user}) {
  const [show, setShow] = useState(false);
  const [passwordError,setPasswordError] = useState("");
  const [currentPassword,setCurrentPassword] = useState("");
  const [newPassword,setNewPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");
  const [zoneShow,setZoneShow] = useState(false);
  const [zoneError,setZoneError] = useState("");
  const [zoneValue,setZoneValue] = useState("");
  const [inputZoneValue,setInputZoneValue] = useState("Asia/Karachi");


  const head = headers
  const setPass = async (argData) => {
 
   
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL_AUTH}/auth/users/set_password/`,
        headers: headers,
        data: argData
      })
        .then((response) => {
          setPasswordError("")
         
         
          //   setData(response.data);
        })
        .catch((error) => setPasswordError(error.response.data.current_password));
   
    
      
      
  };
  
  return (
    <>
      <div>
        {/* The Modal For Password  */}
        <Modal
          open={show}
          onClose={() => setShow(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              height: 350,
              bgcolor: "background.paper",
              border: "2px solid white",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "10px",
            }}
          >
            <div style={{}}>
            <div style={{ display: "flex" }}>
                <div style={{ paddingTop: "8px" }}>Current Password:</div>
                <div style={{ marginLeft: "23px" }}>
                  <Input
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    disableUnderline={true}
                    sx={{
                      boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                      border: "2px solid #0D0992",
                      backgroundColor: "white",
                      width: "250px",
                      borderRadius: "10px",

                      paddingLeft: "15px",
                      paddingBottom: "5px",
                      input: {
                        color: "black",
                        fontWeight: "400",
                        "&::placeholder": {
                          color: "#212121",
                          opacity: 0.8,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ paddingTop: "35px" }}>New Password:</div>
                <div style={{ marginLeft: "45px" }}>
                  <Input
                    placeholder="* * * * * * * *"
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password"
                    disableUnderline={true}
                    sx={{
                      boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                      border: "2px solid #0D0992",
                      backgroundColor: "white",
                      width: "250px",
                      borderRadius: "10px",
                      marginTop:"30px",
                      paddingLeft: "15px",
                      paddingBottom: "5px",
                      input: {
                        color: "black",
                        fontWeight: "400",
                        "&::placeholder": {
                          color: "#212121",
                          opacity: 0.8,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "30px" }}>
                <div style={{ paddingTop: "8px" }}>Confirm Password:</div>
                <div style={{ marginLeft: "21px" }}>
                  <Input
                    placeholder="* * * * * * * *"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    disableUnderline={true}
                    type="password"
                    sx={{
                      boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                      backgroundColor: "white",
                      border: "2px solid #0D0992",
                      width: "250px",
                      borderRadius: "10px",
                     
                      paddingLeft: "15px",
                      paddingBottom: "5px",
                      input: {
                        color: "black",
                        fontWeight: "400",
                        "&::placeholder": {
                          color: "#212121",
                          opacity: 0.8,
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <FormHelperText>{passwordError}</FormHelperText>
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  const data ={
                    current_password:currentPassword,
                    new_password:newPassword,
                    re_new_password:confirmPassword
                  }
                  
                  setPass(data)
                  setShow(false);

                }
                }
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "50px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Save
              </Button>
            </div>
          </Box>
        </Modal>

        {/* The Modal for selecting TimeZones  */}
        <Modal
          open={zoneShow}
          onClose={() => setZoneShow(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              height: 350,
              bgcolor: "background.paper",
              border: "2px solid white",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "10px",
            }}
          >
            <div style={{display:"flex",justifyContent:"center"}}>
                <strong style={{color:"#0D0992"}}>Add TimeZone</strong>
            </div>
            <div style={{ display: "flex",marginTop:"20px" }}>
                
                <div style={{ marginLeft: "23px" }}>
                  <FormControl>
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: "250px", borderRadius: "10px" }}
                  options={timezones}
                  autoHighlight
                  onChange={(event, value) => {
                    setZoneValue(value);
                    if(value == ""){
                      setZoneError("Select Field")
                    }
                    else{
                      setZoneError("")
                    }
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      
                       {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Time Zone"
                      sx={{
                        "& fieldset": {
                          borderRadius: "10px",
                          boxShadow: "1px 1px 1px  #0D0992",
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />

                    
                    )}
                    />
                    <FormHelperText sx={{color:"red",margin:0}}>{zoneError}</FormHelperText>
                    </FormControl>
                </div>
             
              
              </div>
           
            
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  setInputZoneValue(zoneValue)
                  setZoneShow(false)

                }
                }
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "50px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Save
              </Button>
            </div>
          </Box>
        </Modal>

        <div
          style={{
            color: "#0D0992",
            fontWeight: 600,
            fontSize: "20px",
            marginTop: "100px",
            marginLeft: "50px",
          }}
        >
          Profile
        </div>
       
        <div style={{ marginLeft: "50px", marginTop: "100px" }}>

          {/* The email Field  */}
          <div style={{ display: "flex",flexDirection:"column" }}>
            <div style={{ color:"rgba(0, 0, 0, 0.5)" }}>Email</div>
            <div style={{ }}>
              <Input
                value={user[0].email}
                disableUnderline={true}
                // disabled
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                  border: "2px solid #0D0992",
                  backgroundColor: "white",
                  width: "250px",
                  borderRadius: "10px",

                  paddingLeft: "15px",
                  paddingBottom: "5px",
                  input: {
                    color: "black",
                    fontWeight: "400",
                    "&::placeholder": {
                      color: "#212121",
                      opacity: 0.8,
                    },
                  },
                }}
              />
            </div>
          </div>

          {/* The password field  */}
          {/* <div style={{ display: "flex", marginTop: "50px",flexDirection:"column" }}>
            <div style={{ paddingTop: "8px" }}>Password:</div>
            <div style={{ }}>
              <Input
                
                disableUnderline={true}
                endAdornment={
                    <IconButton onClick={() => setShow(true)}>
                    <BorderColorIcon sx={{color:"black"}}/>
                    </IconButton>
                }
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                  backgroundColor: "white",
                  border: "2px solid #0D0992",
                  width: "250px",
                  borderRadius: "10px",

                  paddingLeft: "15px",
                  paddingBottom: "5px",
                  input: {
                    color: "black",
                    fontWeight: "400",
                    "&::placeholder": {
                      color: "#212121",
                      opacity: 0.8,
                    },
                  },
                }}
              />
            </div>
          </div> */}

          {/* The Timezone field  */}
          <div style={{ display: "flex", marginTop: "50px",flexDirection:"column" }}>
            <div style={{ paddingTop: "8px" }}>TimeZone:</div>
            <div style={{ }}>
            <Input
                // value = {inputZoneValue}
                value = {user[0].timezone}
                disableUnderline={true}
                // endAdornment={
                //     <IconButton onClick={() => setZoneShow(true)}>
                //     <BorderColorIcon sx={{color:"black"}}/>
                //     </IconButton>
                // }
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                  backgroundColor: "white",
                  border: "2px solid #0D0992",
                  width: "250px",
                  borderRadius: "10px",

                  paddingLeft: "15px",
                  paddingBottom: "5px",
                  input: {
                    color: "black",
                    fontWeight: "400",
                    "&::placeholder": {
                      color: "#212121",
                      opacity: 0.8,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>

        
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ProfileIndex);
