import React,{useEffect} from 'react'

import {connect} from 'react-redux';
import {checkAuthenticated,load_user} from './actions/auth';
import Home from './pages/Home';
import Login from './pages/Login';
import "./layout.css";

function Layout(props) {
    useEffect(() => {
        props.checkAuthenticated();
        props.load_user();
    },[]);
  return (
    <>
      {props.children}
    </>
  )
}

export default connect(null,{checkAuthenticated,load_user})(Layout)