import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";

import ControlPointIcon from "@mui/icons-material/ControlPoint";

import Button from "@mui/material/Button";
import CustomTextField from "../CustomTextField";
import { getData, addData, deleteData, editData } from "../helpers/helpers";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import RemoveIcon from "@mui/icons-material/Remove";

const authToken =
  "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjgxODgxNDE4LCJqdGkiOiJkYTBjZTlkMmE2NjM0ZWE1OTcxNWIzZjg3OTNkMDdiZCIsInVzZXJfaWQiOjN9.m_EAPPaxp3g18XL4MH-9RKB8I06yvoM-jZkKCynPOnE";

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};

function TagmapIndex() {
  const [loader, setLoader] = useState(true);
  const [tagsCounter, setCounter] = useState(0);
  const [custom, setCustom] = useState("");
  const [tagContainer, setTagContainer] = useState({
    id: ["reference_id", "job_id"],
    title: ["job_title", "jobtitle"],
    description: ["desc", "job_description"],
    cpc: [],
    url: ["link", "job_url"],
    company: ["job_company"],
    category: ["job_categ", "job_category"],
    country: [],
    salary: ["job_sal"],
    city: [],
    state: [],
    jobtype: ["job_type"],
    postalcode: ["zipcode"],
  });
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL_DATA}xml_config`, {
          // .get("http://localhost:8000/api/v1/partners", {
          headers: {
            "content-type": "application/json",
            AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setTagContainer(response.data);
          setLoader(false);
        })
        .catch((error) => console.log(error));
    };

    fetchData();
  }, [refresh]);
  const height = "20px";

  const handler = (key, index, status, changed_value) => {
    
    if (status == "update") {
      let new_obj = {};

      const make_change = async () =>
        (new_obj = {
          ...tagContainer,
          [key]: [...tagContainer[key], ""],
        });

      make_change();

      setTagContainer(new_obj);
    } else if (status == "change") {
      let new_obj = {};

      let new_arr = null;
      const set_array = async () => {
        new_arr = tagContainer[key];
        new_arr[index] = changed_value;
      };

      set_array();

      const make_change = async () => {
        new_obj = { ...tagContainer, [key]: new_arr };
      };

      make_change();
      setTagContainer(new_obj);
    } else if (status == "delete") {
      let new_obj = {};

      let new_arr = null;
      const set_array = async () => {
        new_arr = tagContainer[key];
        new_arr = new_arr.filter((item) => item !== changed_value);
       
      };

      set_array();

      const make_change = async () => {
        new_obj = { ...tagContainer, [key]: new_arr };
      };

      make_change();
      setTagContainer(new_obj);
    }
  };

  return (
    <>
      {loader && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="wrapper">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      )}
      {!loader && (
        <>
          <div style={{ color: "#0D0992", fontWeight: 600, fontSize: "18px",marginTop:"4%",marginLeft:"1%" }}>
            Feed XML Configuration
          </div>
          <div
            style={{
              
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
                    border:"1px solid rgba(0, 0, 0, 0.15)",
              marginTop: "1%",
              marginBottom: "10px",
              paddingBottom: "10px",
              maxWidth: "900px",
              marginLeft: "10px",
            }}
          >
            {tagContainer &&
              Object.keys(tagContainer).map((key,index) => (
                <div
                key={index}
                  style={{
                    display: "flex",
                    width: "900px",
                    height: "40px",
                    marginTop: "3%",
                    paddingLeft: "10px",
                    borderRadius: "10px",
                  }}
                >

                  {/* Master Tag div  */}
                  <div>
                  
                    <CustomTextField
                      setFunction={setCustom}
                      labelName={"Master Tag"}
                      value={key}
                      shrink={true}
                    />
                  </div>
                  <div
                    style={{
                      paddingTop: "6px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#0D0992",
                    }}
                  >
                    :
                  </div>
                  {/* Feed tag div   */}
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      width: "90%",
                    }}
                  >
                    {tagContainer[key].map((value, i) => (
                     
                      <React.Fragment key={i}>
                      
                        <TextField
                          id="outlined-basic"
                          label="Feed Tag"
                          value={value}
                          onChange={(e) => {
                            handler(key, i, "change", e.target.value);
                          }}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => {
                                    handler(key, i, "delete", value);
                                  }}
                                >
                                  <RemoveIcon sx={{ color: "red" }} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiInputLabel-root": {
                              color: "rgba(0, 0, 0, 0.5);",
                              fontSize: "14px",
                              padding: 0,
                            }, //styles the label
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": {
                                borderColor: "none",
                                borderRadius: "10px",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                              },
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                borderColor: "#0D0992",
                              },
                            },
                            width: "200px",
                            marginLeft: "10px",
                          }}
                        />
                      </React.Fragment>
                    ))}

                    <div>
                      {/* key, index, status,changed_value */}
                      <IconButton
                        onClick={() => handler(key, "", "update", "")}
                      >
                        <ControlPointIcon sx={{ color: "#0D0992" }} />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}

            <div style={{ marginTop: "40px", marginLeft: "50px" }}>
              <Button
                variant="contained"
                onClick={() => {
                  addData(tagContainer, "xml_config").then(() => {

                    setRefresh(refresh + 1);
                  });
                }}
                sx={{
                  width: "130px",
                  backgroundColor: "#0D0992E0",
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TagmapIndex;
