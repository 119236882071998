import React from "react";
import Chart from "./Chart";
import DoughnutHalf from "./DoughnutHalf";
import dashboardBanner from "../../assets/dashboard-banner.png";

function DashboardIndex({totalBudget,budgetSpent}) {
  return (
    <>
     
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex",marginTop:"2%"}}>
          <div style={{color:"#0D0992",fontWeight:600,marginLeft:"1%"}}>Total Clicks</div>
          <div style={{color:"#0D0992",fontWeight:600,marginLeft:"45%"}}>Total Budget</div>
        </div>
        <div style={{ display: "flex",marginTop:"2%" }}>
          <div style={{ width: "40%",marginLeft:"70px",paddingTop:"20px",paddingLeft:"20px",paddingBottom:"10px",paddingRight:"10px" ,backgroundColor: "#FFFFFF",
                    border:"0.5px solid #C5C5C5",borderRadius:"13px"}}>
            
              <Chart />
           
          </div>
          <div style={{width:"43%",backgroundColor: "#FFFFFF",
                    border:"0.5px solid #C5C5C5",marginLeft:"8%",display:"flex",justifyContent:"center",borderRadius:"13px"}}>
            <div>
          <DoughnutHalf
                    primaryColor={"#0878FF"}
                    secondaryColor={"#696969"}
                    title={"Budget Spent"}
                    datavalue={[budgetSpent,totalBudget-budgetSpent]}
                    clicks={budgetSpent}
                    innerClick={totalBudget}
                    innerTitle={"Total Budget"}
                    labelvalue={["Budget Spent","Remaining Budget"]}
                  />
                  </div>
          </div>
        </div>
      </div>
      <div
        style={{
          
          
          
          height: "200px",
          
          width:"97%",
          marginTop:"8%",
          borderRadius:"10px"
        }}
      >
        <img src={dashboardBanner} style={{width:"100%",height:"100%"}}/>
      </div>
    </>
  );
}

export default DashboardIndex;
