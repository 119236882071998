import React from 'react'
import Box from "@mui/material/Box";
import DoughnutChart from './DoughnutChart';

function PublisherAnalyticsBox({foreign = 0,duplicate = 0,expire = 0,botClicks=0}) {
  return (
    <>
    <Box sx={{height:"300px"}}>
        <h3>Analytics</h3>
        
        <Box sx={{display:"flex"}}>

            <Box sx={{display:"flex",flexDirection:"column",width:"85px"}}>
              <div style={{marginBottom:"10px"}}>
                <big style={{marginTop:"30px",marginBottom:0,fontSize:"15px",display:"flex"}}><div style={{width:"10px",height:"10px",backgroundColor:"red",borderRadius:"100%",marginRight:"8px",marginTop:"4px"}}></div>Foreign</big>
                <big style={{marginTop:"4px",fontSize:"15px",marginLeft:"20px"}}>{parseInt(((foreign/(foreign+duplicate+expire+botClicks))*100).toFixed(2))}%</big>
              </div>
              <div style={{marginBottom:"10px"}}>
                <big style={{margin:0,fontSize:"15px",display:"flex"}}><div style={{width:"10px",height:"10px",backgroundColor:"#00B7FE",borderRadius:"100%",marginRight:"8px",marginTop:"4px"}}></div>Duplicate</big>
                <big style={{marginTop:"4px",fontSize:"15px",marginLeft:"20px"}}>{parseInt(((duplicate/(foreign+duplicate+expire+botClicks))*100).toFixed(2))}%</big>
              </div>
              <div style={{marginBottom:"10px"}}>
                <big style={{margin:0,fontSize:"15px",display:"flex"}}><div style={{width:"10px",height:"10px",backgroundColor:"#D0D2DA",borderRadius:"100%",marginRight:"8px",marginTop:"4px"}}></div>Expire</big>
                <big style={{marginTop:"4px",fontSize:"15px",marginLeft:"20px"}}>{parseInt(((expire/(foreign+duplicate+expire+botClicks))*100).toFixed(2))}%</big>
              </div>
              <div >
                <big style={{margin:0,fontSize:"15px",display:"flex"}}><div style={{width:"10px",height:"10px",backgroundColor:"#483248",borderRadius:"100%",marginRight:"8px",marginTop:"4px"}}></div>Bot Clicks</big>
                <big style={{marginTop:"4px",fontSize:"15px",marginLeft:"20px"}}>{parseInt(((botClicks/(foreign+duplicate+expire+botClicks))*100).toFixed(2))}%</big>
              </div>
            </Box>
            <Box sx={{}}>
            <div style={{height:"180px",width:"70%",minWidth:"230px",maxWidth:"300px",marginTop:"40px"}}>
                <DoughnutChart foreignclick={foreign} duplicateclick={duplicate} expireclick={expire} botClicks={botClicks}/>
                </div>
                
              
            </Box>

        </Box>
        </Box>
    </>
  )
}

export default PublisherAnalyticsBox