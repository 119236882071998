import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box, { BoxProps } from "@mui/material/Box";
import Container from "@mui/material/Container";
import cm from "../../assets/home-cmatrix.png";
import cl from "../../assets/clefty.png";
import Grid from "@mui/material/Grid";
import { minWidth } from "@material-ui/system";
import { login } from "../actions/auth";
import loginImage from "../../assets/login.png";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

function Login({ login, isAuthenticated,user }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [emailErrorState, setEmailError] = useState("");
  const [passErrorState, setPassError] = useState("");
  const [loginErrorState, setLoginError] = useState("");
  const [visibility,setVisibility] = useState(false)

  const handleClick = async (e) => {
    let decision = true;
    e.preventDefault();
    if (email == null) {
      setEmailError("Email Field Is Empty");
      decision = false;
    }
    if (password == null) {
      setPassError("Password Field Is Empty");
      decision = false;
    }
    if (decision) {
      const logFunc = async () => {
        await login(email, password);
      };
      logFunc().then(() => {
        if (!isAuthenticated) {
          setLoginError("Email Or Password Is Incorrect");
        }
      });
    }
  };

  if (isAuthenticated) {
    if(user[0].is_publisher){
      return <Navigate to="/publisher_table" />;
    }
    else{

      return <Navigate to="/dashboard" />;
    }
  }

  return (
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <form>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          height: "100%",
          borderRadius: "13px",
        }}
      >
        <Box sx={{ marginLeft: "auto", marginRight: "auto", marginTop: "10%" }}>
          <img src={cm} alt="cm" style={{ width: "250px" }}></img>
        </Box>
        <FormControl>
          <TextField
            id="outlined-basic1"
            label="Email"
            variant="standard"
            onChange={(e) => {setEmail(e.target.value);if(loginErrorState != ""){setLoginError("")};if(e.target.value == ""){
              setEmailError("Email Is Empty");

            }else{
              setEmailError("")
            }}}
            sx={{
              "& .MuiInputLabel-root": { color: "grey" }, //styles the label
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  borderColor: "none",
                  borderRadius: "10px",

                  boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: "#FFFFFF",
                },
              },
              "& .MuiInput-underline:before": { borderBottomColor: "white" },
              "& .MuiInput-underline:after": { borderBottomColor: "white" },
              width: "390px",
              marginTop: "50px",
              input: { color: "white" },
            }}
          />
          <FormHelperText sx={{ marginTop: "13px", color: "white" }}>
            {emailErrorState}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            id="outlined-basic2"
            type={visibility ? "text" : "password"}
            label="Password"
            variant="standard"
            onChange={(e) => {setPassword(e.target.value);if(loginErrorState != ""){setLoginError("")};if(e.target.value == ""){
              setPassError("Password Is Empty");

            }else{
              setPassError("")
            }}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => {setVisibility(!visibility)}}>
    
                    <VisibilityIcon sx={{color:"white"}}/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputLabel-root": { color: "grey" }, //styles the label
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  borderColor: "none",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: "#FFFFFF",
                },
              },
              "& .MuiInput-underline:before": { borderBottomColor: "white" },
              "& .MuiInput-underline:after": { borderBottomColor: "white" },
              width: "390px",
              marginTop: "60px",
              input: { color: "white" },
            }}
          />
          <FormHelperText sx={{ marginTop: "13px", color: "white" }}>
            {passErrorState}
          </FormHelperText>
        </FormControl>
        <FormHelperText sx={{ color: "white" }}>
          {loginErrorState}
        </FormHelperText>

        <Button
          type="submit"
          variant="contained"
          onClick={(e) => handleClick(e)}
          sx={{
            borderRadius: 2,
            width: "340px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "70px",
            backgroundColor: "#3925C7",
          }}
        >
          Sign In
        </Button>
      </Box>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user : state.auth.user
});

export default connect(mapStateToProps, { login })(Login);
