import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { getFeedReportData,addData } from "../helpers/helpers";
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";


function FeedReportIndex() {
  const [checked, setChecked] = useState([]);
  const [selectAll,setSelectAll] = useState(false);
  const [container, setContainer] = useState([]);
  const [searched, setSearched] = useState("");
  const [searchContainer, setSearchContainer] = useState([]);
  const [refresh,setRefresh] = useState(0);
  const [reportDialog,setReportDialog] = useState(false);

  const navigate = useNavigate();

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setContainer(searchContainer);
    } else {
      const filteredRows = searchContainer.filter((row) => {
        return row.company_name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setContainer(filteredRows);
    }
  };

  const cancelSearch = () => {
    setContainer(searchContainer);
    setSearched("");
    // requestSearch(searched);
  };

  const handleToggle = (value) => () => {
    setSelectAll(false)
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    
    
    setChecked(newChecked);
  };

  useEffect(() => {  
const displayLoader = async () => {
      await getFeedReportData(setContainer,setSearchContainer, "feeds");
    }
    const check = async () => {
      await displayLoader();
      
    }

    check()
    
  },[refresh])
  return (
    <React.Fragment>


<Dialog
        open={reportDialog}
        onClose={() => {setReportDialog(false);setChecked([])}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogTitle id="alert-dialog-title">
          {"Report Sent"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We have received your <strong>Feeds Report Request</strong>.<br />An <strong>Email</strong> will be sent to you shortly
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:"flex",justifyContent:"flex-end"}}>
          
          
          <Button variant="contained" sx={{backgroundColor:"#0D0992",borderRadius:"7px",fontSize:"13px",marginRight:"10px"}} onClick={() => {
            
            setReportDialog(false);
            setChecked([]);
            
setRefresh(refresh+1)
}} autoFocus>
            Done
          </Button>
          
        </DialogActions>
      </Dialog>


      <div style={{ display: "flex",justifyContent:"space-between",width:"80%",marginTop:"4%" }}>
        <div >
            <div>
              <div style={{display:"flex",justifyContent:"space-between"}}>
              <div>
                <IconButton sx={{backgroundColor:"white",border:"1px solid #C5C5C5",borderRadius:"8px"}} onClick={() => navigate("/feeds")}>
  <ArrowBackIcon />
                </IconButton>
              </div>
              <div>
                {/* The search box  */}
                <TextField
              id="outlined-basic"
              size="small"
              value={searched}
              inputProps={{
                color:"rgba(0, 0, 0, 0.55)"
              }}
              label="Search"
              sx={{
                width: "200px",
                
                marginBottom:"5px",
                ".MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#C5C5C5",
                    borderWidth: "0.5px",
                    color:"rgba(0, 0, 0, 0.55)"
                  },
                },
                "& label.Mui-focused": {
                  color: "rgba(0, 0, 0, 0.55)",
                },
                backgroundColor: "white",
                color:"rgba(0, 0, 0, 0.55)"
              }}
              variant="outlined"
              onChange={(e) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searched != "" && (
                      <IconButton onClick={() => cancelSearch()}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              
            />
            </div>
            </div>
          <div
            style={{ border: "0.5px solid #C5C5C5",borderRadius:"9px", width: "500px",backgroundColor:"white" }}
          >
            <List
              sx={{ width: "100%",overflowY:"auto",minHeight: "500px",height:"500px",}}
            >
                <ListItem disablePadding sx={{borderBottom:"1px solid #C5C5C5"}}>
                <ListItemButton onClick={() => {setChecked(container);setSelectAll(!selectAll);if(selectAll){
                    setChecked([])
                }} }>
                <ListItemIcon>
                        <Checkbox
                        sx={{
                            // color: "#0D0992",
                            '&.Mui-checked': {
                              color: "#0D0992",
                            },
                          }}
                          edge="start"
                          checked={selectAll}
                        
                          
                          disableRipple
                          
                        />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={<Typography variant="body2" style={{ fontWeight:"bold" }}>Feeds</Typography>}
                      />
                </ListItemButton>
                </ListItem>
              {container.map((value,index) => {
                const labelId = `checkbox-list-label-${value.company_name}`;

                return (
                  <ListItem
                    key={index}
                    sx={{"&:focus": {
                        background: "#6c757d",
                        color: "black"
                      },
                    borderBottom:"1px solid #C5C5C5"}}
                    // secondaryAction={
                    //   <IconButton edge="end" aria-label="comments">
                    //     <CommentIcon />
                    //   </IconButton>
                    // }
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value) !== -1}
                        
                          sx={{
                            // color: "#0D0992",
                            '&.Mui-checked': {
                              color: "#0D0992",
                            },
                          }}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${value.company_name}_${value.partner_name}_${value.country}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>

          </div>
        </div>
        <div
          style={{border: "0.5px solid #C5C5C5",borderRadius:"9px", width: "500px",backgroundColor:"white",minHeight:"545px",height:"545px",paddingBottom:"18px",paddingRight:"1px"}}
        >
            <List sx={{height:"100%", overflowY:"auto",}}>
           
                        <ListItem key={"One"} sx={{borderBottom:"1px solid #C5C5C5"}}>
                        <ListItemText
                        disableTypography
                        primary={<Typography variant="body2" style={{ fontWeight:"bold" }}>Selected Feeds</Typography>}
                      />
                            </ListItem>
            {checked.map((value,index) => (
                <React.Fragment key={index}>
                
                    
                        <ListItem key={index} sx={{borderBottom:"1px solid #C5C5C5"}}>
                            <ListItemText>
                                {value.company_name}_{value.partner_name}_{value.country}
                            </ListItemText>
                        </ListItem>
                    
               
               
                        </React.Fragment>

            ))}
            </List>

        </div>
      </div>
      <div style={{marginLeft:"35%",marginTop:"3%"}}>
        <Button variant="contained" sx={{fontWeight:600,backgroundColor:"#0D0992",borderRadius:"8px"}} onClick={() => {let arr = [];
        checked.map((value,index) => {
          arr.push(value.id);
        });
        if(arr.length != 0){

          addData({feeds:arr},"feed_report").then(() => {setReportDialog(true)})
        }

      }}>Generate Report</Button>
      </div>
    </React.Fragment>
  );
}

export default FeedReportIndex;
