import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Box from "@mui/material/Box";
import axios from "axios";

const authToken = `JWT ${localStorage.getItem("access")}`;
// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};

const apiLink = process.env.REACT_APP_API_URL_DATA;

function ApexChart() {
  // const [labelContainer, setLabelContainer] = useState([]);
  // const [dataContainer, setDataContainer] = useState([]);

  // useEffect(() => {
  //   const getData = async () => {
  
  //     await axios
  //       .get(`${apiLink}campaign_clicks`, {
  //         // .get("http://localhost:8000/api/v1/partners", {
  //         headers: {
  //           "content-type": "application/json",
  //           AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
  //         },
  //       })
  //       .then((response) => {
  //         //   setData(response.data);
  //         response.data.map((value) => {
  //           setLabelContainer((arr) => [...arr,value.date]);
  //           setDataContainer((arr) => [...arr,value.total_clicks]);
  //         });
          
  //       })
  //       .catch((error) => console.log(error));
  //   };
  //   getData()
   
    

  //   // let data = [
  //   //   { date: "18", campaign: 0, total: 100 },
  //   //   { date: "19", campaign: 0, total: 110 },
  //   //   { date: "20", campaign: 0, total: 120 },
  //   //   { date: "21", campaign: 0, total: 330 },
  //   //   { date: "22", campaign: 0, total: 250 },
  //   // ];
  //   // data.map((value) => {
  //   //   setLabelContainer((arr) => [...arr,value.date]);
  //   //   setDataContainer((arr) => [...arr,value.total]);
  //   // });
  // }, []);

  
  const data = {
    // labels: ["1", "2", "3", "4", "5", "6","7","8","9","10","11","12", "13", "14", "15", "16", "17","18","19","20","21","22","23","24","25","26","27","28","29","30"],
    labels: [1,2,3,4,5,6,7],
    datasets: [
      {
        label: ["Total Clicks"],
        // data: [33, 53, 85, 41, 44, 65,72,83,88,95,55,33, 53, 85, 41, 44, 65,72,83,88,95,55,43, 50, 54, 91, 74, 55,72,60],
        data: [3000,4000,2500,1500,1800,3000,3300],
        fill: true,
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: "#7549FF",
        pointHoverBorderColor: "white",
        backgroundColor: "#F3F0FF",
        // background:'linear-gradient(180deg, #F3D0FF -13.53%, rgba(1, 237, 255, 0) 98.07%)',
        borderColor: "#7549FF",
      },
    ],
  };
  var options = {
    hover: {
      intersect: false,
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: "rgb(255, 99, 132)",
        },
      },
    },
    legend: {
      usePointStyle: true,
      display: false, // <- the important part
    },
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true
        }
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true
        }
        },
      ],
    },
  };
  return (
    // <div style={{width:"700px",height:"300px",paddingTop:"15px"}}>
    <Line data={data} options={options} />
    // </div>
  );
}

export default ApexChart;
