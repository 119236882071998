import React, { useState, useEffect,useRef } from "react";
import { Box } from "@mui/system";
import feedAddImage from "../../assets/feed-add.png";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
// import countries from "./FeedCountriesData";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CustomTextField from "../CustomTextField";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getData, addData, deleteData, editData } from "../helpers/helpers";
import FormHelperText from "@mui/material/FormHelperText";

const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

function FeedAdd() {
  let partnerRef = useRef(null);
  let companyRef = useRef(null);
  let credentialRef = useRef(null);

  //Component States
  const [partner, setPartner] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [partnerContainer, setPartnerContainer] = useState([]);
  const [companyContainer, setCompanyContainer] = useState([]);
  const [generalstatus, setGeneralStatus] = useState("");
  const [downloadurl, setDownloadUrl] = useState("");
  const [downloadstatus, setDownloadStatus] = useState("");
  const [credentials, setCredentials] = useState("");
  const [ftp, setFtp] = useState("");
  const [statusDWA, setStatusDwa] = useState(false);
  const [statusFTP, setStatusFTP] = useState(false);
  const [partnerModal, setPartnerModal] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [ftpUploadArea, setFtpUploadArea] = useState(0);
  const [ftpUploadAreaId, setFtpUploadAreaId] = useState(null);
  const [ftpUploadUrl, setFtpUploadUrl] = useState("");
  const [credContainer, setCredContainer] = useState([]);
  const [credModal, setCredModal] = useState(false);
  const [mainCredModal, setMainCredModal] = useState(false);
  const [container, setContainer] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const [downloadStatusDefault, setDownloadStatusDefault] = useState("DIRECT");

  //Error Handling States
  const [partnerErrorState, setPartnerError] = useState(false);
  const [companyErrorState, setCompanyError] = useState(false);
  const [countryErrorState, setCountryError] = useState(false);
  const [urlErrorState, setUrlError] = useState(false);
  const [statusErrorState, setStatusError] = useState(false);
  const [ftpErrorState,setFtpError] = useState("")
  const [credentialErrorState,setCredentialError] = useState("")

   //The error for new addition modals
  const [usernameErrorState,setUsernameError] = useState("");
  const [passwordErrorState,setPasswordError] = useState("");
  const [detailsErrorState,setDetailsError] = useState("");

  const [partnerModalError,setPartnerModalError] = useState("");
  const [companyModalError,setCompanyModalError] = useState("");
  const [urlModalError,setUrlModalError] = useState("");
  const [uploadModalError,setUploadModalError] = useState("");

  const [usernameComCredError,setUsernameComCredError] = useState("");
  const [passwordComCredError,setPasswordComCredError] = useState("");
  const [detailsComCredError,setDetailsComCredError] = useState("");

  //For Credentials Modal
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [details, setDetails] = useState("");

  const [credentialUsername, setCredentialUsername] = useState("");
  const [credentialPassword, setCredentialPassword] = useState("");
  const [credentialDetails, setCredentialDetails] = useState("");
  // const [countriesAuto, setCountriesAuto] = useState(countries);

  const navigate = useNavigate();

  useEffect(() => {
    getData(setPartnerContainer, "partners");
    getData(setCompanyContainer, "childcompanies");
    getData(setCredContainer, "credentials");
  }, [refresh]);

  return (
    <>
      {/* The Modal For Partners  */}
      <Modal
        open={partnerModal}
        onClose={() => setPartnerModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
         
          <CustomTextField
                  setFunction={setPartner}
                  labelName={"Partner Name"}
                  refs={partnerRef}
                  onChange={true}
                  setErrorFunction={setPartnerModalError}
                />
          <FormHelperText sx={{color:"red",margin:0}}>{partnerModalError}</FormHelperText>

          <Button
            variant="contained"
            onClick={() => {
              if(partner != ""){

                addData({ name: partner }, "partners").then(() => {
                  getData(setPartnerContainer,"partners",setPartnerId).then(() => {
                    
                    
                    
                    setPartnerModal(false);
                  });
                  
                });
                
              }
              else{
                setPartnerModalError("Partner is Empty")
              }
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

     

      {/* The Modal for Credentials Main Add  */}
      <Modal
        open={mainCredModal}
        onClose={() => setMainCredModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              color: "#0D0992",
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            Enter Credentials
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "350px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: "10px" }}>
                <CustomTextField
                  setFunction={setCredentialUsername}
                  labelName={"Username"}
                  onChange={true}
                  refs={credentialRef}
                  setErrorFunction={setUsernameError}
                  
                />
              <FormHelperText sx={{color:"red",margin:0}}>{usernameErrorState}</FormHelperText>
              </div>
              
              <div style={{ marginTop: "10px" }}>
                <CustomTextField
                  setFunction={setCredentialPassword}
                  labelName={"Password"}
                  onChange={true}
                  setErrorFunction={setPasswordError}
                  
                />
                <FormHelperText sx={{color:"red",margin:0}}>{passwordErrorState}</FormHelperText>
              </div>
              <div style={{ marginTop: "10px" }}>
                <CustomTextField
                  setFunction={setCredentialDetails}
                  labelName={"Details"}
                  onChange={true}
                  setErrorFunction={setDetailsError}
                  shrink={true}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{detailsErrorState}</FormHelperText>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              let decision = true;
              if(credentialUsername == ""){
                setUsernameError("Username Is Empty");
                decision = false;
              }
              if(credentialPassword == ""){
                setPasswordError("Password Is Empty");
                decision = false;
              }
              if(credentialDetails == ""){
                setDetailsError("Details Are Empty");
                decision = false;
              }
              const data = {
                user_name: credentialUsername,
                password: credentialPassword,
                details: credentialDetails,
              };
              if(decision){

                addData(data, "credentials").then(() => {

                  getData(setCredContainer, "credentials",setCredentials).then(() => {

                    setMainCredModal(false);
                  });
                });
              }
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

      {/* The Modal For Companies  */}
      <Modal
        open={companyModal}
        onClose={() => setCompanyModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 370,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              color: "#0D0992",
              fontWeight: 600,
              fontSize: "20px",
              marginBottom: "35px",
              marginRight: "45px",
            }}
          >
            Enter Company Details
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "45%",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: "30px" }}>
              {/* For Input Fields  */}
              <div>
                <CustomTextField
                  setFunction={setCompanyName}
                  labelName={"Company Name"}
                  setErrorFunction={setCompanyModalError}
                  onChange={true}
                  refs={companyRef}
                  
                />
                <FormHelperText sx={{color:"red",margin:0}}>{companyModalError}</FormHelperText>
              </div>
             
              <div style={{ marginTop: "35px" }}>
                <CustomTextField
                  labelName={"Redirect URL"}
                  setFunction={setFtpUploadUrl}
                  setErrorFunction={setUrlModalError}
                  onChange={true}
                  
                />
                <FormHelperText sx={{color:"red",margin:0}}>{urlModalError}</FormHelperText>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              let decision = true;
              var data = {
                name: companyName,
                
                redirect_url: ftpUploadUrl,
              };
              if(companyName == ""){
                setCompanyModalError("Company Is Empty");
                decision = false;
              }
             
              if(ftpUploadUrl == ""){
                setUrlModalError("Url is Empty")
                decision = false;
              }
              if(decision){

                addData(data, "childcompanies").then(() => {

                  getData(setCompanyContainer,"childcompanies",setCompany).then(() => {
                    setCompanyModal(false);

                  });
                });
              }

            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "60px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

      <Box sx={{ display: "flex", height: "90%", width: "100%" }}>
        <div style={{ flex: 2 }}>
        <div style={{marginLeft:"80px"}}>
          <IconButton sx={{backgroundColor:"white",border:"1px solid #C5C5C5",borderRadius:"8px"}} onClick={() => navigate("/feeds")}>
  <ArrowBackIcon />
                </IconButton>
          </div>
          <div
            style={{
              paddingTop: "20px",
              paddingLeft: "80px",
              color: "#0D0992",
              fontWeight: 700,
              fontSize: "25px",
              marginTop: "8px",
            }}
          >
            Add Feeds
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "450px",

              height: "600px",
              justifyContent: "space-around",
              marginLeft: "25%",
              marginTop: "40px",
            }}
          >
            <div
              style={{
                display: "flex",

                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: "5px",
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                Partner
              </div>
              <div style={{ display: "flex", marginLeft: "140px" }}>
                <FormControl size="small">
                  <InputLabel id="partner-label">Select Partner</InputLabel>
                  <Select
                    size="small"
                    labelId="partner-label"
                    id="demo-simple-select"
                    value={partnerId}
                    onChange={(e) => {
                      if(e.target.value.name == ""){
                        setPartnerError("Select Field")
                      }
                      else{
                        setPartnerError("")
                      }
                      
                      setPartnerId(e.target.value);
                    }}
                    sx={{
                      width: "250px",
                      borderRadius: "10px",
                      "& fieldset":{border:"1px solid rgba(0, 0, 0, 0.15)"},
                      backgroundColor:"white",
                    }}
                    label="Select partner"
                  >
                    {partnerContainer.map((value) => (
                      <MenuItem value={value.id} key={value.id}>{value.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {partnerErrorState}
                  </FormHelperText>
                </FormControl>

                <div style={{ paddingTop: "5px", marginLeft: "10px" }}>
                  <IconButton
                    onClick={() => {const timeout = setTimeout(() => {
                      partnerRef.current.focus();
                    }, 100);setPartnerModal(true)}}
                    sx={{
                      padding: "0px",
                      marginLeft: "20px",
                      marginTop: "2px",
                      height: "34px",
                      width: "35px",
                      border: "1px solid #C4C4C4",
                      borderRadius: "20%",
                      "&:hover": {
                        border: "1px solid #0D0992",
                        color: "#0D0992",
                        background: "white",
                      },
                    }}
                  >
                    <AddIcon sx={{ fontSize: "24px" }} />
                  </IconButton>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  paddingTop: "10px",
                  marginTop: "8px",
                }}
              >
                Company
              </div>
              <div style={{ display: "flex", marginLeft: "124px" }}>
                <FormControl size="small">
                  <InputLabel id="partner-label">Select Company</InputLabel>
                  <Select
                    size="small"
                    labelId="partner-label"
                    id="demo-simple-select"
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value);
                      if(e.target.value.id == ""){
                        setCompanyError("Select Field")
                      }
                      else{
                        setCompanyError("")
                      }
                    }}
                    sx={{
                      width: "250px",
                      borderRadius: "10px",
                      "& fieldset":{border:"1px solid rgba(0, 0, 0, 0.15)"},
                      backgroundColor:"white",
                    }}
                    label="Select partner"
                  >
                    {companyContainer.map((value) => (
                      <MenuItem value={value.id} key={value.id}>{value.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {companyErrorState}
                  </FormHelperText>
                </FormControl>
                <div style={{ paddingTop: "5px", marginLeft: "10px" }}>
                  <IconButton
                    onClick={() => {const timeout = setTimeout(() => {
                      companyRef.current.focus();
                    }, 100);setCompanyModal(true)}}
                    sx={{
                      padding: "0px",
                      marginLeft: "20px",
                      marginTop: "2px",
                      height: "34px",
                      width: "35px",
                      border: "1px solid #C4C4C4",
                      borderRadius: "20%",
                      "&:hover": {
                        border: "1px solid #0D0992",
                        color: "#0D0992",
                        background: "white",
                      },
                    }}
                  >
                    <AddIcon sx={{ fontSize: "24px" }} />
                  </IconButton>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: "5px",
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                Country
              </div>
              <div style={{}}>
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: "250px", borderRadius: "10px",backgroundColor:"white" }}
                  options={countries}
                  autoHighlight
                  
                  size="small"
                  onChange={(event, value) => {setCountry(value)
                  setCountryError("Select Field")
                  if(value == ""){
                    setCountryError("Select Field")
                  }
                  else{
                    setCountryError("")
                  }}}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      sx={{
                        "& fieldset": {
                          borderRadius: "10px",
                          border:"1px solid rgba(0, 0, 0, 0.15)",
                     
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "country", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {countryErrorState}
                </FormHelperText>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: "5px",
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                Download URL
              </div>
              <div style={{paddingTop:"8px"}}>
                <Input
                  placeholder="Enter Download URL"
                  disableUnderline={true}
                  onChange={(e) => {setDownloadUrl(e.target.value)
                    if(e.target.value.name == ""){
                      setUrlError("Field Is Empty")
                    }
                    else{
                      setUrlError("")
                    }}}
                  sx={{
                    border:"1px solid rgba(0, 0, 0, 0.15)",
                      backgroundColor:"white",
                    backgroundColor: "white",
                    width: "250px",
                    height: "36px",
                    borderRadius: "10px",

                    paddingLeft: "15px",
                    paddingBottom: "5px",
                    input: {
                      color: "black",
                      fontWeight: "400",
                      "&::placeholder": {
                        color: "#212121",
                        opacity: 0.6,
                      },
                    },
                  }}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {urlErrorState}
                </FormHelperText>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: "5px",
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                Download Status
              </div>
              <div style={{}}>
                <FormControl size="small">
                  <InputLabel id="comapny-label">Download Status</InputLabel>
                  <Select
                    size="small"
                    labelId="comapny-label"
                    id="demo-simple-select"
                    value={downloadStatusDefault}
                    onChange={(e) => {
                      if (e.target.value === "AUTH") {
                        setStatusDwa(true);
                        setStatusFTP(false);
                      }
                      if (e.target.value === "FTP") {
                        setStatusDwa(true);
                        setStatusFTP(true);
                      }

                      if (e.target.value === "DIRECT") {
                        setStatusDwa(false);
                        setStatusFTP(false);
                      }
                      setDownloadStatusDefault(e.target.value);
                      setGeneralStatus(e.target.value);
                      setDownloadStatus(e.target.value);
                    }}
                    sx={{
                      width: "250px",
                      borderRadius: "10px",
                      "& fieldset":{border:"1px solid rgba(0, 0, 0, 0.15)"},
                      backgroundColor:"white",
                    }}
                    label="Download Status"
                  >
                    <MenuItem value={"AUTH"}>
                      Direct With Authentication
                    </MenuItem>
                    <MenuItem value={"FTP"}>FTP Area</MenuItem>
                    <MenuItem value={"DIRECT"}>Direct</MenuItem>
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {statusErrorState}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            {statusDWA && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "5px",
                      color: "rgba(13, 9, 146,  0.7",
                      fontWeight: 600,
                      fontSize: "18px",
                      marginTop: "8px",
                    }}
                  >
                    Credentials
                  </div>
                  <div style={{ display: "flex", marginLeft: "110px" }}>
                    <div>
                      <FormControl size="small">
                        <InputLabel id="comapny-label">
                          Select Credential
                        </InputLabel>
                        <Select
                          MenuProps={{ disablePortal: true }}
                          size="small"
                          value={credentials}
                          onChange={(e) => {setCredentials(e.target.value)
                            if(e.target.value.name == ""){
                              setCredentialError("Select Field")
                            }
                            else{
                              setCredentialError("")
                            }}}
                          labelId="comapny-label"
                          id="demo-simple-select"
                          sx={{
                            width: "250px",
                            borderRadius: "10px",
                            "& fieldset":{border:"1px solid rgba(0, 0, 0, 0.15)"},
                      backgroundColor:"white",
                            "&:hover": {
                              "&& fieldset": {
                                border: "1px solid #0D0992",
                              },
                            },
                            "&:focus": {
                              "&& fieldset": {
                                border: "1px solid red",
                              },
                            },
                          }}
                          label="Select Credentials"
                        >
                          {credContainer.map((value) => (
                            <MenuItem value={value.id} key={value.id}>{value.details}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText sx={{ color: "red" }}>
                    {credentialErrorState}
                  </FormHelperText>
                      </FormControl>
                    </div>
                    <div style={{ paddingTop: "5px", marginLeft: "10px" }}>
                      <IconButton
                        onClick={() => {const timeout = setTimeout(() => {
                          credentialRef.current.focus();
                        }, 100);setMainCredModal(true)}}
                        sx={{
                          padding: "0px",
                          marginLeft: "20px",
                          marginTop: "2px",
                          height: "34px",
                          width: "35px",
                          border: "1px solid #C4C4C4",
                          borderRadius: "20%",
                          "&:hover": {
                            border: "1px solid #0D0992",
                            color: "#0D0992",
                            background: "white",
                          },
                        }}
                      >
                        <AddIcon sx={{ fontSize: "24px" }} />
                      </IconButton>
                    </div>
                  </div>
                </div>
                {statusFTP && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "5px",
                          color: "rgba(13, 9, 146,  0.7",
                          fontWeight: 600,
                          fontSize: "18px",
                          marginTop: "8px",
                        }}
                      >
                        FTP Area Filename
                      </div>
                      <div style={{paddingTop:"8px"}}>
                        <Input
                          placeholder="Enter FTP Filename"
                          disableUnderline={true}
                          onChange={(e) => {setFtp(e.target.value);
                            if(e.target.value.name == ""){
                              setFtpError("Select Field")
                            }
                            else{
                              setFtpError("")
                            }}}
                          sx={{
                            border:"1px solid rgba(0, 0, 0, 0.15)",
                      backgroundColor:"white",
                            backgroundColor: "white",
                            width: "250px",
                            height: "36px",
                            borderRadius: "10px",

                            paddingLeft: "15px",
                            paddingBottom: "2px",
                            input: {
                              color: "black",
                              fontWeight: "400",
                              "&::placeholder": {
                                color: "#212121",
                                opacity: 0.8,
                              },
                            },
                          }}
                        />
                        <FormHelperText sx={{ color: "red" }}>
                    {ftpErrorState}
                  </FormHelperText>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div style={{ paddingLeft: "25%", paddingTop: "50px" }}>
            <Button
              variant="contained"
              onClick={async () => {
                let status_temp = downloadStatusDefault;
                let decision = true;
                const statusChange = () => {
                  if (downloadStatusDefault == "AUTH") {
                    if(credentials == ""){
                      setCredentialError("Credentials Must Be Selected")
                      decision = false;
                    }
                    status_temp = "DIRECT";
                  }
                  if (partnerId == "") {
                    setPartnerError("Partner Should Be Selected");
                    decision = false;
                  }
                  if (company == "") {
                    setCompanyError("Company Should Be Selected");
                    decision = false;
                  }
                  if (country == "") {
                    setCountryError("Country Should Be Selected");
                    decision = false;
                  }
                  if (downloadurl == "") {
                    setUrlError("Url Can't Be Empty");
                    decision = false;
                  }
                  if (status_temp == "FTP") {
                    if (ftp == "") {
                      setFtpError("FTP Filename Can't Be Empty");
                      decision = false;
                    }
                    if(credentials == ""){
                      setCredentialError("Credential Must Be Selected")
                      decision = false;
                    }
                  }
                };
                await statusChange();
                if (decision) {
                  const data = {
                    company: company,

                    country: country.code,
                    url: downloadurl,
                    downloadstatus: status_temp,
                    credentials: credentials,
                    ftp_filename: ftp,
                    partner: partnerId,
                  };

                  let c = await addData(data, "feeds");
                  if (c !== null) {
                    navigate("/feeds");
                  }
                }
              }}
              sx={{
                backgroundColor: "#0D0992",
                width: "140px",
                borderRadius: "8px",
              }}
            >
              Save
            </Button>
          </div>
        </div>
        {/* </div> */}
        <div style={{ flex: 1 }}>
          {/* <div
            style={{
              height: "100%",
              marginTop: "90px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <img src={feedAddImage} style={{ width: "100%" }} alt="feedAdd" />
          </div> */}
        </div>
      </Box>
    </>
  );
}

export default FeedAdd;
