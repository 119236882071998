import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalNav from "../../Navbar/VerticalNav";
import CampaignAnalyticsBox from "../../Campaigns/CampaignAnalyticsBox";
import CampaignAnalyticsProgressBox from "../../Campaigns/CampaignAnalyticsProgressBox";
import DoughnutHalf from "../../Charts/DoughnutHalf";
import Chart from "../../Charts/Chart";
import ProfileMenu from "../../Profile/ProfileMenu";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getDataId } from "../../helpers/helpers";
import Button from "@mui/material/Button";
import axios from "axios";
import BarChart from "../../Charts/Barchart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { Collapse } from "@mui/material";

const authToken = `JWT ${localStorage.getItem("access")}`;

// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;

function CampaignAnalytics({ isAuthenticated }) {
  const params = useParams();
  const [open,setOpen] = useState(false);
  const [campaign_stats, setCampaignStats] = useState([]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    getDataId(setCampaignStats, "campaign_stats", params.id);
  }, []);

  const handleClick = async () => {
    await axios
      .get(`${apiLink}stats_csv/${params.id}`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = `data:text/csv;charset=utf-8,${escape(response.data)}`;
        link.download = `${campaign_stats.filename}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.log(error));
  };
  const navigate = useNavigate();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Grid container sx={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
        <Grid item md={2} sx={{ height: "100%" }}>
          <VerticalNav />
        </Grid>
        <Grid
          item
          md={10}
          sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}
        >
          <ProfileMenu />

          <div style={{ marginLeft: "20px" }}>
            <div>
              <IconButton
                sx={{
                  backgroundColor: "white",
                  border: "1px solid #C5C5C5",
                  borderRadius: "8px",
                }}
                onClick={() => navigate("/campaigns")}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
            <Collapse
                              in={open}
                              timeout="auto"
                              unmountOnExit
                              sx={{marginTop:"20px",marginLeft:"25%"}}
                            >
            
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{}}>
                <DateRangePicker
                  onChange={(item) => {
                    setState([item.selection]);
                    console.log("the date picker selection is :", [
                      item.selection,
                    ]);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={state}
                  direction="horizontal"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "550px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => console.log("")}
                  sx={{
                    backgroundColor: "#402DCC",
                    borderRadius: "8px",
                    width: "130px",
                    height: "33px",
                    marginLeft: "1%",
                    minWidth: "130px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <strong>Filter</strong>
                </Button>
              </div>
            </div>
              </Collapse>
          </div>
          <div style={{display:'flex',justifyContent:"space-between",width:"92%"}}>
            <div>
            <Button
              variant="contained"
              onClick={() => handleClick()}
              sx={{
                backgroundColor: "#402DCC",
                borderRadius: "8px",
                width: "130px",
                height: "33px",
                marginLeft: "15%",
                minWidth: "130px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <strong>Export CSV</strong>
            </Button>
            </div>
            <div><Button variant="contained"
              onClick={() => setOpen(!open)}
              sx={{
                backgroundColor: "#402DCC",
                borderRadius: "8px",
                width: "170px",
                height: "33px",
                marginLeft: "1%",
                minWidth: "130px",
                marginTop: "20px",
                marginBottom: "20px",
              }}>
                <strong>Range Picker</strong>
                </Button></div>
          </div>
          <div
            style={{
              marginLeft: "30px",
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {campaign_stats.filename}
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "59%",
                marginLeft: "1%",
                border: "0.5px solid #C5C5C5 ",
                borderRadius: "13px",
                backgroundColor: "white",
              }}
            >
              <div style={{ paddingTop: "50px" }}>
                <DoughnutHalf
                  primaryColor={"#0878FF"}
                  secondaryColor={"#696969"}
                  title={"Budget Spent"}
                  datavalue={[
                    campaign_stats.budget_spent,
                    campaign_stats.total_budget - campaign_stats.budget_spent,
                  ]}
                  clicks={
                    campaign_stats.valid_clicks *
                    parseFloat(campaign_stats.global_cpc)
                  }
                  innerClick={campaign_stats.total_budget}
                  innerTitle={"Total Budget"}
                  labelvalue={["Budget Spent", "Remaining Budget"]}
                  type="budget"
                />
              </div>
              <div style={{ paddingTop: "50px" }}>
                <DoughnutHalf
                  primaryColor={"#EE2B1F"}
                  secondaryColor={"#888888"}
                  title={"Valid Clicks"}
                  datavalue={[
                    campaign_stats.valid_clicks,
                    campaign_stats.total_clicks - campaign_stats.valid_clicks,
                  ]}
                  clicks={campaign_stats.valid_clicks}
                  innerClick={campaign_stats.total_clicks}
                  labelvalue={["Valid Clicks", "Invalid Clicks"]}
                  innerTitle={"Total Clicks"}
                />
              </div>
            </div>
            <div
              style={{
                border: "0.5px solid #C5C5C5 ",
                borderRadius: "13px",
                backgroundColor: "white",
                paddingLeft: "15px",
                marginLeft: "1%",
                width:"35%"
              }}
            >
              <CampaignAnalyticsBox
                foreign={campaign_stats.foreign_clicks}
                duplicate={campaign_stats.duplicate_clicks}
                expire={campaign_stats.expired_clicks}
                botClicks={campaign_stats.bot_clicks}
              />
              {/* <CampaignAnalyticsProgressBox totalclicks={campaign_stats.total_clicks} android={campaign_stats.android_clicks} linux={campaign_stats.linux_clicks} windows={campaign_stats.windows_clicks} mac={campaign_stats.mac_clicks} ios={campaign_stats.ios_clicks}/> */}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "1%" }}>
            <div
              style={{
                width: "56.5%",
                border: "0.5px solid #C5C5C5 ",
                borderRadius: "13px",
                backgroundColor: "white",
                height: "330px",
                paddingLeft: "40px",
                marginLeft: "1%",
                paddingTop: "50px",
              }}
            >
              {/* <Chart campaignId={params.id}/> */}
              <BarChart campaignId={params.id} />
            </div>
            {/* <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                paddingLeft: "15px",
              }}
            >
              <CampaignAnalyticsBox foreign={campaign_stats.foreign_clicks} duplicate={campaign_stats.duplicate_clicks} expire={campaign_stats.expired_clicks} botClicks={campaign_stats.bot_clicks} />
            </div> */}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(CampaignAnalytics);
