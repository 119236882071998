import React from 'react'
import { Doughnut } from 'react-chartjs-2';


function DoughnutChart({foreignclick,duplicateclick,expireclick,botClicks}) {
   const data = {
        
        datasets: [
          {
            label: '# of Votes',
            data: [foreignclick, duplicateclick, expireclick,botClicks],
            backgroundColor: [
              '#FD2254',
              '#00B7FE',
              '#D0D2DA',
              '#483248',
            
            ],
            borderColor: [
                '#FD2254',
                '#00B7FE',
                '#D0D2DA',
                '#483248',
             
            ],
            borderWidth: 1,
            
            hoverOffset: 2,
          },
        ],
        labels:["Foreign Clicks","Duplicate Clicks","Expire CLicks","Bot Clicks"]
      };
      const options = {
        elements: {
            arc: {
                
                borderJoinStyle:'miter',
                
                
            }
          },
          responsive: true,
    maintainAspectRatio: false,
          cutoutPercentage: 80,
          rotation: 4,
          // tooltips: {enabled: false},
          legend: {
            display: false  // <- the important part
          },
        
         
        
        
      }
  return (
    
    <Doughnut
    options={options}
    data={data}
   
    
    
  />
 
  )
}

export default DoughnutChart