import axios from "axios";

const authToken = `JWT ${localStorage.getItem("access")}`;
// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;

const getData = async (setContainer, entity,optState="null") => {
  
  await axios
    .get(`${apiLink}${entity}`, {
      // .get("http://localhost:8000/api/v1/partners", {
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      //   setData(response.data);
      setContainer((arr) => (arr = [...response.data]));
      if(optState != "null"){
        optState(response.data[response.data.length - 1].id)
      }
    })
    .catch((error) => console.log(error));
};

const getDataTwo = async (setContainer,setSearchContainer ,entity,optState="null") => {
  
  await axios
    .get(`${apiLink}${entity}`, {
      // .get("http://localhost:8000/api/v1/partners", {
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      //   setData(response.data);
      setContainer((arr) => (arr = [...response.data]));
      setSearchContainer((arr) => (arr = [...response.data]));
      if(optState != "null"){
        optState(response.data[response.data.length - 1].id)
      }
    })
    .catch((error) => console.log(error));
};


const getFeedReportData = async (setContainer, setSecondContainer,entity) => {
  
  await axios
    .get(`${apiLink}${entity}`, {
      // .get("http://localhost:8000/api/v1/partners", {
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      //   setData(response.data);
      setContainer((arr) => (arr = [...response.data]));
      setSecondContainer((arr) => (arr = [...response.data]));
    })
    .catch((error) => console.log(error));
};

const CampaignStatusRequest = async (stat,campaign_id) => {
  await axios({
    method: "put",
    url: `${apiLink}campaign_status/${campaign_id}/`,
    headers: {
      "content-type": "application/json",
      AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
    },
    data: {"status":stat},
  })
    .then((response) => {
      
     
     
    })
    .catch((error) => console.log());
}

const getData2 = (setContainer,setSearchContianer,setLoader,setBudget,setLiveCount,setSpentBudget,setTotalCount, entity) => {
  var spent = 0;
  var spentbudget = 0;
  let live_campaign = 0;
  axios
    .get(`${apiLink}${entity}`, {
      // .get("http://localhost:8000/api/v1/partners", {
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      //   setData(response.data);
      setContainer((arr) => (arr = [...response.data]));
      setSearchContianer((arr) => (arr = [...response.data]));
      response.data.map((value) => {
        value["loader"] = false;
        spent = parseInt(value.budget)+spent;
        if(value.CampaignStats[0].length != 0){

          spentbudget = spentbudget + ((value.CampaignStats[0].valid_clicks)*(parseFloat(value.CampaignStats[0].global_cpc)))
        }
        if(value.status == "enabled"){
          live_campaign += 1;
        }
        
      });
      
      setLoader(false)
      setBudget(spent);
      setTotalCount(response.data.length);
      setLiveCount(live_campaign)
      setSpentBudget(spentbudget)
    })
    .catch((error) => console.log("Error:",error));
};

const getPublisher = (setContainer,setTotalBudget,setTotalRevenue,setTotalClicks,setTotalValidClicks,setTotalInvalidClicks,entity) => {
  var revenue = 0;
  var invalid = 0;
  var valid = 0;
  var budget = 0;
  var total = 0;
  axios
    .get(`${apiLink}${entity}`, {
      // .get("http://localhost:8000/api/v1/partners", {
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      //   setData(response.data);
      setContainer((arr) => (arr = [...response.data]));
      response.data.map((value) => {
        
        revenue += value.CampaignStats[0].budget_spent;
        total += value.CampaignStats[0].total_clicks;
        invalid += value.CampaignStats[0].invalid_clicks;
        valid += value.CampaignStats[0].valid_clicks;
        budget += parseInt(value.CampaignStats[0].total_budget);
        

        
      });
      
      setTotalBudget(budget);
      setTotalClicks(total);
      setTotalInvalidClicks(invalid);
      setTotalValidClicks(valid);
      setTotalRevenue(revenue.toFixed(2));
    })
    .catch((error) => console.log());
};



const getDataId = async (setContainer, entity, id) => {
  await axios
    .get(`${apiLink}${entity}/${id}`, {
      // .get("http://localhost:8000/api/v1/partners", {
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      //   setData(response.data);

      setContainer(response.data);
    })
    .catch((error) => console.log());
};



const addData = async (argData, entity) => {
  let pass = null;
  const temp = async () => {
    await axios({
      method: "post",
      url: `${apiLink}${entity}/`,
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
      data: argData,
    })
      .then((response) => {
        pass = response.data;

        //   setData(response.data);
      })
      .catch((error) => console.log());
  };

  await temp();
  return pass;
};

const editData = async (argData, entity, id) => {
  await axios({
    method: "put",
    url: `${apiLink}${entity}/${id}/`,
    headers: {
      "content-type": "application/json",
      AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
    },
    data: argData,
  })
    .then((response) => {
      //   setData(response.data);
    })
    .catch((error) => console.log());
};

const deleteData = async (entity, id) => {
  await axios({
    method: "delete",
    url: `${apiLink}${entity}/${id}/`,
    headers: {
      "content-type": "application/json",
      AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
    },
  })
    .then((response) => {
      //   setData(response.data);
    })
    .catch((error) => console.log());
};

const setPass = async (argData) => {
  
  await axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL_AUTH}/auth/users/set_password/`,
    headers:  {
      "content-type": "application/json",
      AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
    },
    data: argData,
  })
    .then((response) => {
      return response;

      //   setData(response.data);
    })
    .catch((error) => console.log());
};

export { getData,getDataTwo, addData, editData, deleteData, getDataId, getFeedReportData, setPass, getData2,getPublisher, CampaignStatusRequest,headers };
