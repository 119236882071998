import React from "react";
import homecmatrix from "../../assets/home-cmatrix.png";
import homecmatrixbg from "../../assets/home-cmatrix-bg.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import report from "../../assets/report.png";
import optimize from "../../assets/optimize.png";
import personchart from "../../assets/person-chart.png";
import integrate from "../../assets/integrate.png";
import detect from "../../assets/detect.png";
import cmatrix2 from "../../assets/home2-cmatrix.png";
import cmatrixbottom from "../../assets/cmatrix-new-bottom.png";
import office from "../../assets/cmatrix-office.png";
import { logout } from "../actions/auth";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

function Home() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
          backgroundImage: `url(${homecmatrixbg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "40%",
            }}
          >
            <img src={homecmatrix} alt="main" style={{ width: "250px" }} />
          </div>
          <div style={{ color: "white", fontSize: "24px", marginTop: "90px" }}>
            Our Service Based Model enables all <br />
            sort of organization to use our quality
            <br /> services.
          </div>
          <div style={{ marginLeft: "auto", marginTop: "50px" }}>
            <Link to="/login">
              <Button
                variant="outlined"
                sx={{
                  ":hover": { borderColor: "#0D0992", color: "white" },
                  border: "2px solid rgba(255, 255, 255, 0.5)",
                  color: "rgba(255, 255, 255, 0.7);",
                  borderRadius: "7px",
                  width: "100px",
                }}
              >
                Log In
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div style={{ height: "100vh", width: "100%" }}>
        {/* The div for login bar  */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div>
            <img src={cmatrix2} alt="we" />
          </div>
          <div style={{ marginRight: "30px" }}>
            <Link to="/login">
              <Button
                variant="outlined"
                sx={{
                  border: "2px solid #0D0992",
                  color: "#0D0992",
                  borderRadius: "7px",
                  width: "100px",
                  fontWeight: 600,
                }}
              >
                Log In
              </Button>
            </Link>
          </div>
        </div>
        {/* The div for Services  */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            top: "150px",
          }}
        >
          <div style={{ fontSize: "30px" }}>
            <span style={{ color: "#0D0992" }}>Our</span>&nbsp;Matchless&nbsp;
            <span style={{ color: "#0D0992" }}>services</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "300px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <img src={report} style={{ width: "50px" }} alt="reporting" />
                </div>
                <div
                  style={{
                    width: "100px",
                    marginLeft: "10px",
                    color: "#0D0992",
                  }}
                >
                  Explicit
                  <br />
                  Reporting
                </div>
              </div>
              <div style={{ display: "flex", marginBottom: "50px" }}>
                <div>
                  <img
                    src={optimize}
                    style={{ width: "50px" }}
                    alt="optimization"
                  />
                </div>
                <div
                  style={{
                    width: "100px",
                    marginLeft: "10px",
                    color: "#0D0992",
                  }}
                >
                  Campaign
                  <br />
                  Optimization
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img src={personchart} alt="person-middle" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "300px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    src={integrate}
                    style={{ width: "50px" }}
                    alt="reporting"
                  />
                </div>
                <div
                  style={{
                    width: "100px",
                    marginLeft: "10px",
                    color: "#0D0992",
                  }}
                >
                  Efforless
                  <br />
                  Integration
                </div>
              </div>
              <div style={{ display: "flex", marginBottom: "50px" }}>
                <div>
                  <img
                    src={detect}
                    style={{ width: "50px" }}
                    alt="optimization"
                  />
                </div>
                <div
                  style={{
                    width: "100px",
                    marginLeft: "10px",
                    color: "#0D0992",
                  }}
                >
                  Bot
                  <br />
                  Detection
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: "absolute", width: "100%" }}>
          <img src={cmatrixbottom} style={{ width: "100%", height: "450px" }} />
        </div>
      </div>

      {/* The div for New Page  */}
      <div style={{ height: "100vh", width: "100%" }}>
        {/* Div for login and flex text  */}
        <div
          style={{
            height: "100vh",
          }}
        >
          {/* The div for login bar  */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div>
              <img src={cmatrix2} alt="we" />
            </div>
            <div style={{ marginRight: "30px" }}>
              <Link to="/login">
                <Button
                  variant="outlined"
                  sx={{
                    border: "2px solid #0D0992",
                    color: "#0D0992",
                    borderRadius: "7px",
                    width: "100px",
                    fontWeight: 600,
                  }}
                >
                  Log In
                </Button>
              </Link>
            </div>
          </div>
          {/* THe div for flex text image with bottom image  */}
          {/* <div style={{ border: "1px solid red" }}> */}
          {/* The div for text with image  */}

            <div
              style={{
                display: "flex",
                marginLeft: "15%",
                zIndex: 10,
                position: "absolute",
                marginTop:"8%"
              }}
            >
              <div style={{ marginTop: "4%" }}>
                <img src={office} />
              </div>

              <div>
                <div
                  style={{
                    color: "#0D0992",
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  Absolute <span style={{ color: "#407BFF" }}>Job</span>{" "}
                  <div style={{ color: "#407BFF" }}>Advertising </div>Solution
                </div>
                <p style={{ color: "rgba(0, 0, 0, 0.7)" }}>
                  Our All in One job advertising solution cover all your <br />{" "}
                  needs at one place. Our state of the art programmatic <br />{" "}
                  tech ensures your money is spend well finding the <br /> best
                  candidate for your job providing you with simple <br /> yet
                  advanced analytics facilitating you to make right <br />{" "}
                  decision for your business.Recruitment optimized <br />{" "}
                  Bot/Spam Protection makes sure your ads are in front <br /> of
                  real candidates and help you identify the faulty <br />{" "}
                  traffic source.
                </p>
              </div>
            </div>

            <div style={{ width: "100%", top:"35%",position:"relative"}}>
              <img
                src={cmatrixbottom}
                style={{ width: "100%", height: "600px" }}
              />
            </div>
          </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Home);
