import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from "@mui/material/TableSortLabel";

import {Link} from 'react-router-dom';

import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/system";
import DeleteIcon from '@mui/icons-material/Delete';
import { getData, getDataTwo,addData, deleteData, editData } from "../helpers/helpers";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CustomTextField from "../CustomTextField";
import { useNavigate } from "react-router-dom"

function TableBox() {
  const [loader,setLoader] = useState(true);
  const [container, setContainer] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [country,setCountry] = useState("");
  const [partner,setPartnerName] = useState("");
  const [company,setCompanyName] = useState("");
  const [partnerId,setPartnerId] = useState("");
  const [feedModal,setFeedModal] = useState(false);
  const [editId,setEditId] = useState(null);
  const [deleteDialog,setDeleteDialog] = useState(false);
  const [deleteItem,setDeleteItem] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company_name");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [feedBackupContainer,setFeedBackupContainer] = useState([]);
  const [searched, setSearched] = useState("");

  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const displayLoader = async () => {
      await getDataTwo(setContainer, setFeedBackupContainer,"feeds");
    }
    const check = async () => {
      await displayLoader();
      setLoader(false)
    }

    check()
    

    // setContainer((arr) => arr = [...abu]);
  }, [refresh]);

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setContainer(feedBackupContainer);
    } else {
      const filteredRows = feedBackupContainer.filter((row) => {
        return row.partner_name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setContainer(filteredRows);
      setPage(0);
    }
  };

  const cancelSearch = () => {
    setContainer(feedBackupContainer);
    setSearched("");
    // requestSearch(searched);
  };


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  };

  function descendingComparator(obj1, obj2, orderBy) {
   
    return obj1[orderBy].localeCompare(obj2[orderBy]);
  }

  function getComparator(order, orderBy, obj1, obj2) {
    return order === "desc"
      ? descendingComparator(obj1, obj2, orderBy)
      : -descendingComparator(obj1, obj2, orderBy);
  }

  function stableSort(array, orderIs, orderBy) {
    const stabilizedThis = array.map((el, index) => {
      
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      
      const order = getComparator(orderIs, orderBy, a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
    {loader && <div style={{display:"flex",justifyContent:"center"}}><div className="wrapper">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
</div></div>}
{!loader && <>

  <Dialog
        open={deleteDialog}
        onClose={() => {setDeleteDialog(false);setDeleteItem({})}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete <strong>{deleteItem.filename}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:"flex",justifyContent:"space-between"}}>
          
          <Button variant="outlined" sx={{border:"1px solid #0D0992",color:"#0D0992",borderRadius:"7px",fontSize:"13px"}} onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button variant="contained" sx={{backgroundColor:"#0D0992",borderRadius:"7px",fontSize:"13px"}} onClick={() => {setDeleteDialog(false);deleteData("feeds",deleteItem.id).then(() => {

setRefresh(refresh+1)
})}} autoFocus>
            Confirm
          </Button>
          
        </DialogActions>
      </Dialog>

<Modal
        open={feedModal}
        onClose={() => setFeedModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            Enter Credentials
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", marginTop: "20px" }}>
             
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "10px" }}>
                  <CustomTextField
                    setFunction={setCompanyName}
                    labelName={"Company Name"}
                    value = {company}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <CustomTextField
                    setFunction={setPartnerName}
                    labelName={"Partner Name"}
                    value={partner}
                  />
                </div>
              </div>
            </div>
            <div>
             
              <div style={{ marginTop: "10px" }}>
                <CustomTextField
                  setFunction={setCountry}
                  labelName={"Country Name"}
                  value={country}
                />
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              const data = {
                company_name: company,
                partner_name: partner,
                country: country,
                partner:partnerId
              };
              
              editData(data, "feeds",editId);
              setRefresh(refresh+1)
             
              setFeedModal(false);
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

            <div style={{display:"flex",marginTop:"4%",justifyContent:"space-between"}}>
              <div style={{marginLeft:"10px"}}>
              <Link to="/feeds/new"><Button variant="contained" sx={{backgroundColor:"#0D0992",fontWeight:600,paddingBottom:"10px",paddingLeft:"20px",paddingRight:"20px",borderRadius:"8px"}}>Add Feed</Button></Link>
              <Link to="/feeds/reports"><Button variant="contained" sx={{marginLeft:"10px",backgroundColor:"#0D0992",fontWeight:600,paddingBottom:"10px",paddingLeft:"20px",paddingRight:"20px",borderRadius:"8px",marginRight:"20px"}}>Reports</Button></Link>  
              </div>
            <div style={{paddingRight:"40px"}}>
                <TextField
              id="outlined-basic"
              size="small"
              value={searched}
              inputProps={{
                color: "rgba(0, 0, 0, 0.55)",
              }}
              label="Search"
              sx={{
                width: "230px",
                ".MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#C5C5C5",
                    borderWidth: "0.5px",
                    color: "rgba(0, 0, 0, 0.55)",
                  },
                },
                "& label.Mui-focused": {
                  color: "rgba(0, 0, 0, 0.55)",
                },
                backgroundColor: "white",
                color: "rgba(0, 0, 0, 0.55)",
              }}
              variant="outlined"
              onChange={(e) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searched != "" && (
                      <IconButton onClick={() => cancelSearch()}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
                </div>
            </div>

      <TableContainer
        component={Paper}
        sx={{
          width: "97%",
          marginLeft: "10px",
          marginTop:"10px",
          borderRadius: "10px",
          boxShadow: 2,
        }}
      >
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  
                  color:"rgba(0, 0, 0, 0.7)",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  borderBottom: "1px solid #E0E0E0",
                  fontSize: "16px",
                }}
              >
               <TableSortLabel
                        active={orderBy === "company_name"}
                        direction={orderBy === "company_name" ? order : "asc"}
                        onClick={() => handleRequestSort("company_name")}
                      > <strong>Company</strong></TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontSize: "16px",
                  color:"rgba(0, 0, 0, 0.7)",
                  borderBottom: "1px solid #E0E0E0",
                }}
              >
              <TableSortLabel
                        active={orderBy === "partner_name"}
                        direction={orderBy === "partner_name" ? order : "asc"}
                        onClick={() => handleRequestSort("partner_name")}
                      >   <strong>Partner</strong></TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontSize: "16px",
                  color:"rgba(0, 0, 0, 0.7)",
                  borderBottom: "1px solid #E0E0E0",
                }}
              >
                <TableSortLabel
                        active={orderBy === "country"}
                        direction={orderBy === "country" ? order : "asc"}
                        onClick={() => handleRequestSort("country")}
                      > <strong>Country</strong></TableSortLabel>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  
                  color:"rgba(0, 0, 0, 0.7)",
                  borderBottom: "1px solid #E0E0E0",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {stableSort(container, order, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value,index) => (
          <React.Fragment key={index}>
          
            
            
          
            <TableRow
              key="1"
              // sx={{ "&:last-child td, &:last-child th": { border: "1px solid grey" } }}
            >
              <TableCell
                component="th"
                scope="row"
                align="center"
                sx={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  color:"rgba(0, 0, 0, 0.55)",
                  fontSize: "16px",
                  borderBottom: "1px solid #E0E0E0",
                }}
              >
                {value.company_name}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  color:"rgba(0, 0, 0, 0.55)",
                  fontSize: "16px",
                  borderBottom: "1px solid #E0E0E0",
                }}
              >
                {value.partner_name}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  color:"rgba(0, 0, 0, 0.55)",
                  fontSize: "16px",
                  borderBottom: "1px solid #E0E0E0",
                }}
              >
                {value.country}
              </TableCell>

              <TableCell
                align="center"
                sx={{ borderBottom: "1px solid #E0E0E0"}}
              >
                {/* <Box sx={{display:"flex",justifyContent:"space-between"}}> */}

                <IconButton aria-label="expand-row" size="small" onClick={() => {
                  navigate(`/feed/edit/${value.id}`)
                }}>
                  <BorderColorIcon size="small" />
                </IconButton>

                <IconButton aria-label="expand-row" size="small" sx={{ marginLeft: "5%" }} onClick={() => {
                  setDeleteDialog(true);
                  setDeleteItem({"id":value.id,"filename":value.company_name})
                }}>
                  <DeleteIcon size="small" />
                </IconButton>

                {/* <IconButton
                  aria-label="expand-row"
                  size="small"
                  onClick={() => setOpen(!open)}
                  sx={{ marginLeft: "15%" }}
                >
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton> */}

                {/* </Box> */}
              </TableCell>
            </TableRow>
          
                      
                    
          
          </React.Fragment>
          ))}
          </TableBody>
          <TableFooter>
                      <TableRow>
                      <TablePagination
      
      count={container.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[4,10,20,30]}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </TableRow>
    </TableFooter>
        </Table>
      </TableContainer>
      </>}
    </>
  );
}

export default TableBox;
