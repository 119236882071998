import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { getData,getDataTwo, addData, deleteData, editData } from "../helpers/helpers";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import pageImage from "../../assets/country-add.png";
import CustomTextField from "../CustomTextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TableSortLabel from "@mui/material/TableSortLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { FormHelperText, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& label": {
      width: "100%",
      // paddingTop:"-50px",

      "&.Mui-focused": {
        transform: "translate(-6px, -12px) scale(0.8)",
        transformOrigin: "center",
        fontSize: "15px !important",
        color: "#0D0992",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "none",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
        height: "40px",
      },
    },
    // '&.shrink': {
    //   'transform': 'translate(15px, 0px) scale(1.05)',
    // },
  },
});
function FtpConfigIndex() {
  let ftpRef = useRef();

  const [loader, setLoader] = useState(true);
  const [check, setcheck] = useState("hello world");
  const [container, setContainer] = useState([]);
  const [show, setShow] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [details, setDetails] = useState("");
  const [ftpHost, setFtpHost] = useState("");
  const [campaignDownloadUrl, setCampaignDownloadUrl] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const [credentialId, setCredentialId] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");
  const [ftpBackupContainer,setFtpBackupContainer] = useState([]);
  const [searched, setSearched] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Error Handling States
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [detailsError, setDetailsError] = useState("");
  const [ftpHostError, setFtpHostError] = useState("");
  const [campaignDownloadError, setCampaignError] = useState("");

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const displayLoader = async () => {
      await getDataTwo(setContainer,setFtpBackupContainer, "ftpconfig");
    };
    const check = async () => {
      await displayLoader();
      setLoader(false);
    };

    check();

    // setContainer((arr) => arr = [...abu]);
  }, [refresh]);

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setContainer(ftpBackupContainer);
    } else {
      const filteredRows = ftpBackupContainer.filter((row) => {
        return row.user_name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setContainer(filteredRows);
      setPage(0);
    }
  };

  const cancelSearch = () => {
    setContainer(ftpBackupContainer);
    setSearched("");
    // requestSearch(searched);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  };

  function descendingComparator(obj1, obj2, orderBy) {
   
    return obj1[orderBy].localeCompare(obj2[orderBy]);
  }

  function getComparator(order, orderBy, obj1, obj2) {
    return order === "desc"
      ? descendingComparator(obj1, obj2, orderBy)
      : -descendingComparator(obj1, obj2, orderBy);
  }

  function stableSort(array, orderIs, orderBy) {
    const stabilizedThis = array.map((el, index) => {
      
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      
      const order = getComparator(orderIs, orderBy, a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      {loader && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="wrapper">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      )}
      {!loader && (
        <>
          <Dialog
            open={deleteDialog}
            onClose={() => {
              setDeleteDialog(false);
              setDeleteItem({});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to delete <strong>{deleteItem.filename}</strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #0D0992",
                  color: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => setDeleteDialog(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => {
                  setDeleteDialog(false);
                  deleteData("ftpconfig", deleteItem.id).then(() =>
                    setRefresh(refresh + 1)
                  );
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* The modal for add credentials  */}
          <Modal
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                height: 450,
                bgcolor: "background.paper",
                border: "2px solid white",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  color: "#0D0992",
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                Add Credentials
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "25%",
                  height: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "350px",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginTop: "20px" }}>
                    <CustomTextField
                      setFunction={setUserName}
                      labelName={"Username"}
                      onChange={true}
                      refs={ftpRef}
                      setErrorFunction={setUsernameError}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {usernameError}
                    </FormHelperText>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomTextField
                      setFunction={setPassword}
                      labelName={"Password"}
                      onChange={true}
                      setErrorFunction={setPasswordError}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {passwordError}
                    </FormHelperText>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomTextField
                      setFunction={setDetails}
                      labelName={"Details"}
                      onChange={true}
                      setErrorFunction={setDetailsError}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {detailsError}
                    </FormHelperText>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomTextField
                      setFunction={setFtpHost}
                      labelName={"Ftp_Host"}
                      onChange={true}
                      setErrorFunction={setFtpHostError}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {ftpHostError}
                    </FormHelperText>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomTextField
                      setFunction={setCampaignDownloadUrl}
                      labelName={"Download Url"}
                      onChange={true}
                      setErrorFunction={setCampaignError}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {campaignDownloadError}
                    </FormHelperText>
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  let decision = true;
                  const data = {
                    user_name: username,
                    password: password,
                    details: details,
                    ftp_host: ftpHost,
                    download_url: campaignDownloadUrl,
                  };
                  if (username == "") {
                    setUsernameError("Username Is Empty");
                    decision = false;
                  }
                  if (password == "") {
                    setPasswordError("Password Is Empty");
                    decision = false;
                  }
                  if (details == "") {
                    setDetailsError("Details Are Empty");
                    decision = false;
                  }
                  if (ftpHost == "") {
                    setFtpHostError("Field is empty");
                    decision = false;
                  }
                  if (campaignDownloadUrl == "") {
                    setCampaignError("Field is emtpy");
                    decision = false;
                  }
                  if (decision) {
                    addData(data, "ftpconfig").then(() => {
                      setRefresh(refresh + 1);
                      setShow(false);
                    });
                  }
                }}
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "120px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Save
              </Button>
            </Box>
          </Modal>
          {/* The modal for editing credentials  */}
          <Modal
            open={editModal}
            onClose={() => setEditModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                height: 450,
                bgcolor: "background.paper",
                border: "2px solid white",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  color: "#0D0992",
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                Enter Credentials
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "25%",
                  height: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginTop: "120px" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginTop: "80px" }}>
                      <CustomTextField
                        setFunction={setUserName}
                        labelName={"Username"}
                        onChange={true}
                        setErrorFunction={setUsernameError}
                        value={username}
                        width={"200px"}
                      />
                      <FormHelperText sx={{ color: "red", margin: 0 }}>
                        {usernameError}
                      </FormHelperText>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <CustomTextField
                        setFunction={setPassword}
                        labelName={"Password"}
                        onChange={true}
                        setErrorFunction={setPasswordError}
                        value={password}
                        width={"200px"}
                      />
                      <FormHelperText sx={{ color: "red", margin: 0 }}>
                        {passwordError}
                      </FormHelperText>
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomTextField
                      setFunction={setDetails}
                      labelName={"Details"}
                      onChange={true}
                      setErrorFunction={setDetailsError}
                      value={details}
                      width={"200px"}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {detailsError}
                    </FormHelperText>
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomTextField
                      setFunction={setFtpHost}
                      labelName={"Ftp_Host"}
                      onChange={true}
                      setErrorFunction={setFtpHostError}
                      value={ftpHost}
                      width={"200px"}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {ftpHostError}
                    </FormHelperText>
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomTextField
                      setFunction={setCampaignDownloadUrl}
                      labelName={"Download Url"}
                      onChange={true}
                      setErrorFunction={setCampaignError}
                      value={campaignDownloadUrl}
                      width={"200px"}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {campaignDownloadError}
                    </FormHelperText>
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  let decision = true;

                  const data = {
                    user_name: username,
                    password: password,
                    details: details,
                    ftp_host: ftpHost,
                    download_url: campaignDownloadUrl,
                  };
                  if (username == "") {
                    setUsernameError("Username Is Empty");
                    decision = false;
                  }
                  if (password == "") {
                    setPasswordError("Password Is Empty");
                    decision = false;
                  }
                  if (details == "") {
                    setDetailsError("Details Are Empty");
                    decision = false;
                  }
                  if (ftpHost == "") {
                    setFtpHostError("Field is empty");
                    decision = false;
                  }
                  if (campaignDownloadUrl == "") {
                    setCampaignError("Field is empty");
                    decision = false;
                  }
                  if (decision) {
                    editData(data, "ftpconfig", credentialId).then(() => {
                      setRefresh(refresh + 1);
                      setEditModal(false);
                    });
                  }
                }}
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "200px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Update
              </Button>
            </Box>
          </Modal>
<div style={{display:'flex',marginTop:"4%",justifyContent:"space-between"}}>
          <div
            style={{
              color: "#0D0992",
              fontWeight: 600,
              fontSize: "20px",
              marginLeft:"1.7%",
              width: "100px",
            }}
          >
            <div>
            <Button
              variant="contained"
              onClick={() => {
                setShow(true);
                setUsernameError("");
                setPasswordError("");
                setDetailsError("");
                setUserName("");
                const timeout = setTimeout(() => {
                  ftpRef.current.focus();
                }, 100);
                setPassword("");
                setDetails("");
                setFtpHost("");
                setCampaignDownloadUrl("");
                setCampaignError("");
                setFtpHostError("");
              }}
              sx={{
                backgroundColor: "#0D0992",
                borderRadius: "8px",
                width: "210px",
                minWidth:"200px",
                fontWeight:600,
                height: "37.5px",
              }}
            >
              New Configuration
            </Button>
            </div>
          </div>
          <div style={{paddingRight:"5%",display:'flex'}}>
          <div>
                <TextField
              id="outlined-basic"
              size="small"
              value={searched}
              inputProps={{
                color: "rgba(0, 0, 0, 0.55)",
              }}
              label="Search"
              sx={{
                width: "230px",
                
                ".MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#C5C5C5",
                    borderWidth: "0.5px",
                    color: "rgba(0, 0, 0, 0.55)",
                  },
                },
                "& label.Mui-focused": {
                  color: "rgba(0, 0, 0, 0.55)",
                },
                backgroundColor: "white",
                color: "rgba(0, 0, 0, 0.55)",
              }}
              variant="outlined"
              onChange={(e) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searched != "" && (
                      <IconButton onClick={() => cancelSearch()}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
                </div>
                
          </div>
          </div>
          <div style={{ width: "95%" }}>
            {/* Div for Table  */}
            <div
              style={{
                borderRadius: "15px",

                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
                
                paddingBottom: "40px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  marginLeft: "10px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "13px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid rgba(0, 0, 0, 0.15)",
                  marginTop: "20px",
                }}
              >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ color: "grey", paddingLeft: "60px" }}
                      >
                        <TableSortLabel
                        active={orderBy === "user_name"}
                        direction={orderBy === "user_name" ? order : "asc"}
                        onClick={() => handleRequestSort("user_name")}
                      ><strong>User Name</strong></TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "grey", paddingLeft: "60px" }}
                      >
                        <TableSortLabel
                        active={orderBy === "password"}
                        direction={orderBy === "password" ? order : "asc"}
                        onClick={() => handleRequestSort("password")}
                      ><strong>Password</strong></TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "grey", paddingLeft: "60px" }}
                      >
                        <TableSortLabel
                        active={orderBy === "ftp_host"}
                        direction={orderBy === "ftp_host" ? order : "asc"}
                        onClick={() => handleRequestSort("ftp_host")}
                      ><strong>ftp_host</strong></TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "grey", paddingLeft: "60px" }}
                      >
                        <TableSortLabel
                        active={orderBy === "download_url"}
                        direction={orderBy === "download_url" ? order : "asc"}
                        onClick={() => handleRequestSort("download_url")}
                      ><strong>download_url</strong></TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "grey", paddingLeft: "60px" }}
                      >
                        <TableSortLabel
                        active={orderBy === "details"}
                        direction={orderBy === "details" ? order : "asc"}
                        onClick={() => handleRequestSort("details")}
                      ><strong>details</strong></TableSortLabel>
                      </TableCell>

                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(container, order, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{ width: "10%", paddingLeft: "60px" }}
                          >
                            {value.user_name}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "10%", paddingLeft: "60px" }}
                          >
                            {value.password}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "10%", paddingLeft: "60px" }}
                          >
                            {value.ftp_host}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "35%", paddingLeft: "60px" }}
                          >
                            {value.download_url}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "25%", paddingLeft: "60px" }}
                          >
                            {value.details}
                          </TableCell>
                          {/* <TableCell
                    align="left"
                    sx={{ width: "10%", paddingLeft: "60px" }}
                  >
                    0.00
                  </TableCell> */}
                          <TableCell align="left" sx={{ width: "20%" }}>
                            {/* <Box sx={{display:"flex",justifyContent:"space-between"}}> */}

                            <IconButton
                              onClick={() => {
                                setCredentialId(value.id);
                                setUserName(value.user_name);
                                setPassword(value.password);
                                setDetails(value.details);
                                setFtpHost(value.ftp_host);
                                setCampaignDownloadUrl(value.download_url);
                                setUsernameError("");
                                setPasswordError("");
                                setDetailsError("");
                                setFtpHostError("");
                                setCampaignError("");
                                setEditModal(true);
                              }}
                            >
                              <EditIcon
                                sx={{ fontSize: "21px" }}
                              />
                            </IconButton>

                            <IconButton
                              onClick={() => {
                                setDeleteDialog(true);
                                setDeleteItem({
                                  id: value.id,
                                  filename: value.user_name,
                                });
                              }}
                            >
                              <DeleteIcon
                              
                                sx={{  fontSize: "21px",marginLeft: "5%" }}
                              />
                            </IconButton>
                            {/* </Box> */}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                  <TableFooter>
                      <TableRow>
                      <TablePagination
      
      count={container.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5,10,20,30]}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </TableRow>
    </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
          {/* <div
            style={{
              width: "30%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: "auto",
            }}
          >
            <div>
                  <img
                    src={pageImage}
                    alt="bottom-creative"
                    style={{ width: "450px", height: "450px" }}
                  />
                </div>
          </div> */}
        </>
      )}
    </>
  );
}

export default FtpConfigIndex;
