import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import axios from 'axios';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Paper from "@mui/material/Paper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState, useEffect } from "react";
import { IconButton, Input } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CircularProgress from '@mui/material/CircularProgress';
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";
import TableRowData from "./CampaignTableRowData";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import SearchBar from "material-ui-search-bar";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  getData,
  getData2,
  addData,
  deleteData,
  editData,
  CampaignStatusRequest,
} from "../helpers/helpers";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import "./campaignToggle.css";
import "../Loader/Loader.css";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { CopyToClipboard } from "react-copy-to-clipboard";

function TableBox({ setLiveCount, setBudget, setSpentBudget, setTotalCount }) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [statsContainer, setStatsContainer] = useState("");
  const [open, setOpen] = useState(-1);
  const [campaignContainer, setCampaignContainer] = useState([]);
  const [campaignSearchContianer, setCampaignSearchContainer] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [budgetSpent, setBudgetSpent] = useState("");
  const [campaignUrl, setCampaignUrl] = useState("Campaign.com");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("filename");
  const [loadStatus,setLoadStatus] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setCampaignContainer(campaignSearchContianer);
    } else {
      const filteredRows = campaignSearchContianer.filter((row) => {
        return row.filename.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setCampaignContainer(filteredRows);
      setPage(0);
    }
  };

  const cancelSearch = () => {
    setCampaignContainer(campaignSearchContianer);
    setSearched("");
    // requestSearch(searched);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    var spent = 0;
    const displayLoader = async () => {
      getData2(
        setCampaignContainer,
        setCampaignSearchContainer,
        setLoader,
        setBudget,
        setLiveCount,
        setSpentBudget,
        setTotalCount,
        "campaigns"
      );
    };

    displayLoader();

    setSpentBudget(spent);
  }, [refresh]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  };

  function descendingComparator(obj1, obj2, orderBy) {
    if (orderBy == "total_clicks" || orderBy == "valid_clicks") {
      // console.log("The positive is :",obj1['CampaignStats'][0][orderBy])
      // return obj1['CampaignStats'][0][orderBy].localeCompare(obj2['CampaignStats'][0][orderBy])
      if (
        obj2["CampaignStats"][0][orderBy] < obj1["CampaignStats"][0][orderBy]
      ) {
        return -1;
      }
      if (
        obj2["CampaignStats"][0][orderBy] > obj1["CampaignStats"][0][orderBy]
      ) {
        return 1;
      }
      return 0;
    }
    if (orderBy == "jobs_exported") {
      if (obj2[orderBy] < obj1[orderBy]) {
        return -1;
      }
      if (obj2[orderBy] > obj1[orderBy]) {
        return 1;
      }
      return 0;
    }
    if (orderBy == "spend") {
      if (parseInt(obj2[orderBy]) < parseInt(obj1[orderBy])) {
        return -1;
      }
      if (parseInt(obj2[orderBy]) > parseInt(obj1[orderBy])) {
        return 1;
      }
      return 0;
    }
    return obj1[orderBy].localeCompare(obj2[orderBy]);
  }

  function getComparator(order, orderBy, obj1, obj2) {
    return order === "desc"
      ? descendingComparator(obj1, obj2, orderBy)
      : -descendingComparator(obj1, obj2, orderBy);
  }

  function stableSort(array, orderIs, orderBy) {
    const stabilizedThis = array.map((el, index) => {
      el["spend"] = Math.round(
        (100 / el.budget) *
          (el.CampaignStats[0].valid_clicks *
            parseFloat(el.CampaignStats[0].global_cpc))
      );
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      
      const order = getComparator(orderIs, orderBy, a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const changeCampaignStatus = async (status, campaign_id) => {
    const authToken = `JWT ${localStorage.getItem("access")}`;
// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;

    var updated_status = null;
    if (status == "enabled") {
      updated_status = "disabled";
    } else if (status == "disabled") {
      updated_status = "enabled";
    }
    // CampaignStatusRequest(updated_status, campaign_id)
    setCampaignContainer(campaignContainer.map(el => (el.id === campaign_id ? Object.assign({}, el, { loader:true}) : el)))
    await axios({
      method: "put",
      url: `${apiLink}campaign_status/${campaign_id}/`,
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
      data: {"status":updated_status},
    })
      .then((response) => {
        
       
        setCampaignContainer(campaignContainer.map(el => (el.id === campaign_id ? Object.assign({}, el, { loader:false,status:updated_status }) : el)))
       
      })
      .catch((error) => {
        setErrorDialog(true)
      });

    // CampaignStatusRequest(updated_status, campaign_id).then(() => {
    //   // let cp_copy = campaignContainer;
    //   // console.log("The value of cp_copy is :",cp_copy)
    //   // campaignContainer.map((value) => {
    //   //   if (value.id == campaign_id) {
    //   //     value.status = updated_status;
    //   //     value.loaderss = true;
          
    //   //   }
    //   }
    //   )
      
   
    // setLoadStatus(false)
  };

  return (
    <>
      {loader && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="wrapper">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      )}
      {!loader && (
        <>
          <Dialog
            open={deleteDialog}
            onClose={() => {
              setDeleteDialog(false);
              setDeleteItem({});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to delete <strong>{deleteItem.filename}</strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #0D0992",
                  color: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => setDeleteDialog(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => {
                  setDeleteDialog(false);
                  deleteData("campaigns", deleteItem.id).then(() => {
                    setRefresh(refresh + 1);
                  });
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={errorDialog}
            onClose={() => {
              setErrorDialog(false);
              
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Server Side Issue"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <strong>Please contact admin!</strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              
            </DialogActions>
          </Dialog>

          <div
            style={{
              marginLeft: "10px",
              width: "97%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Link to="/campaign/new">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0D0992",
                    borderRadius: "8px",
                    width: "152px",
                    height: "36px",
                  }}
                >
                  <strong>New Campaign</strong>
                </Button>
              </Link>
            </div>
            <TextField
              id="outlined-basic"
              size="small"
              value={searched}
              inputProps={{
                color: "rgba(0, 0, 0, 0.55)",
              }}
              label="Search"
              sx={{
                width: "17%",
                ".MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#C5C5C5",
                    borderWidth: "0.5px",
                    color: "rgba(0, 0, 0, 0.55)",
                  },
                },
                "& label.Mui-focused": {
                  color: "rgba(0, 0, 0, 0.55)",
                },
                backgroundColor: "white",
                color: "rgba(0, 0, 0, 0.55)",
              }}
              variant="outlined"
              onChange={(e) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searched != "" && (
                      <IconButton onClick={() => cancelSearch()}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Paper
            sx={{
              width: "97%",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              borderRadius: "13px",
              border: "0.5px solid #C5C5C5",
              marginTop: "20px",
            }}
          >
           
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "13px",
                border: "0.5px solid #C5C5C5",
              }}
            >
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        color: "grey",
                        
                        paddingLeft: "45px",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "status"}
                        direction={orderBy === "status" ? order : "asc"}
                        onClick={() => handleRequestSort("status")}
                      >
                      <strong>Live</strong>
                      </TableSortLabel>
                      </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                      <TableSortLabel
                        active={orderBy === "filename"}
                        direction={orderBy === "filename" ? order : "asc"}
                        onClick={() => handleRequestSort("filename")}
                      >
                        <strong>Name</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                    <TableSortLabel
                        active={orderBy === "spend"}
                        direction={orderBy === "spend" ? order : "asc"}
                        onClick={() => handleRequestSort("spend")}
                      >
                      <strong>Spend</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                      <TableSortLabel
                        active={orderBy === "total_clicks"}
                        direction={orderBy === "total_clicks" ? order : "asc"}
                        onClick={() => handleRequestSort("total_clicks")}
                      >
                        <strong>Total Clicks</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                      <TableSortLabel
                        active={orderBy === "valid_clicks"}
                        direction={orderBy === "valid_clicks" ? order : "asc"}
                        onClick={() => handleRequestSort("valid_clicks")}
                      >
                        <strong>Valid Clicks</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                      <TableSortLabel
                        active={orderBy === "global_cpc"}
                        direction={orderBy === "global_cpc" ? order : "asc"}
                        onClick={() => handleRequestSort("global_cpc")}
                      >
                        <strong>CPC</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                      <TableSortLabel
                        active={orderBy === "jobs_exported"}
                        direction={orderBy === "jobs_exported" ? order : "asc"}
                        onClick={() => handleRequestSort("jobs_exported")}
                      >
                        <strong>Job Exported</strong>
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {console.log("The campaign container is :",campaignContainer)}
                  {stableSort(campaignContainer, order, orderBy)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((value, index) => (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              width: "10%",
                              paddingTop: "40px",
                              paddingBottom: "30px",
                              paddingLeft: "40px",
                            }}
                          >
                           {value.loader ? <CircularProgress /> : <label className="switch">
                              {value.status == "enabled" && (
                                <input
                                  type="checkbox"
                                  defaultChecked={true}
                                  onClick={(e) => {
                                    changeCampaignStatus(
                                      value.status,
                                      value.id
                                    );
                                  }}
                                />
                              )}
                              {value.status == "disabled" && (
                                <input
                                  type="checkbox"
                                  defaultChecked={false}
                                  onClick={(e) => {
                                    changeCampaignStatus(
                                      value.status,
                                      value.id
                                    );
                                  }}
                                />
                              )}
                              <span className="slider"></span>
                            </label>}
                            
                            

                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "15%",
                              paddingTop: "30px",
                              paddingBottom: "30px",
                              color: "rgba(0, 0, 0, 0.55)",
                            }}
                          >
                            <strong>{value.filename}</strong>
                          </TableCell>
                          <TableCell align="center" sx={{ width: "120px" }}>
                            <Box sx={{ color: "rgba(0, 0, 0, 0.55)" }}>
                              <strong>
                                $
                                {(
                                  value.CampaignStats[0].valid_clicks *
                                  parseFloat(value.CampaignStats[0].global_cpc)
                                ).toFixed(2)}
                              </strong>{" "}
                              of {value.budget}$
                              <LinearProgress
                                variant="determinate"
                                value={Math.round(
                                  (100 / value.budget) *
                                    (value.CampaignStats[0].valid_clicks *
                                      parseFloat(
                                        value.CampaignStats[0].global_cpc
                                      ))
                                )}
                                sx={{
                                  marginTop: "3px",
                                  width: "100%",
                                  borderRadius: "15px",
                                  height: "5px",

                                  "& .MuiLinearProgress-bar1Determinate": {
                                    backgroundColor: "#FD2254",
                                  },
                                }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "rgba(0, 0, 0, 0.55)" }}
                          >
                            {value.CampaignStats[0].total_clicks}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "rgba(0, 0, 0, 0.55)" }}
                          >
                            {value.CampaignStats[0].valid_clicks}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "rgba(0, 0, 0, 0.55)" }}
                          >
                            {value.global_cpc}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "rgba(0, 0, 0, 0.55)" }}
                          >
                            {value.jobs_exported}
                          </TableCell>
                          {/* <TableCell
                      align="center"
                      sx={{ width: "10%",  }}
                    >
                      0.00
                    </TableCell> */}
                          <TableCell
                            align="center"
                            sx={{
                              width: "270px",
                              color: "rgba(0, 0, 0, 0.55)",
                            }}
                          >
                            {/* <Box sx={{display:"flex",justifyContent:"space-between"}}> */}

                            <IconButton
                              aria-label="expand-row"
                              size="small"
                              onClick={() => {
                                navigate(`/campaign/edit/${value.id}`);
                              }}
                            >
                              <BorderColorIcon size="small" />
                            </IconButton>

                            <IconButton
                              aria-label="expand-row"
                              size="small"
                              sx={{ marginLeft: "5%" }}
                              onClick={() => {
                                navigate(`/campaign-analytics/${value.id}`);
                              }}
                            >
                              <AssessmentIcon size="small" />
                            </IconButton>

                            <IconButton
                              aria-label="expand-row"
                              size="small"
                              onClick={() =>
                                setOpen(open === index ? -1 : index)
                              }
                              sx={{ marginLeft: "5%" }}
                            >
                              {open === index ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton>

                            <IconButton
                              aria-label="expand-row"
                              size="small"
                              sx={{ marginLeft: "5%" }}
                              onClick={() => {
                                // deleteData("campaigns", value.id).then(() => {
                                //   setRefresh(refresh + 1);
                                // });
                                setDeleteItem({
                                  id: value.id,
                                  filename: value.filename,
                                });
                                setDeleteDialog(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            {/* </Box> */}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={8}
                          >
                            <Collapse
                              in={open === index}
                              timeout="auto"
                              unmountOnExit
                            >
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    flexDirection: "column",
                                  }}
                                >
                                  {/* The div for campaign url  */}
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      <Input
                                        placeholder="Enter Filename"
                                        disableUnderline={true}
                                        value={value.download_url}
                                        // onChange={(e) => {
                                        //   setCampaignName(e.target.value);
                                        //   if (e.target.value == "") {
                                        //     setCampaignError("Field Is Empty");
                                        //   } else {
                                        //     setCampaignError("");
                                        //   }
                                        // }}
                                        endAdornment={
                                          <CopyToClipboard
                                            text={value.download_url}
                                          >
                                            <IconButton
                                              style={{ marginRight: "30px" }}
                                            >
                                              <ContentCopyIcon />
                                            </IconButton>
                                          </CopyToClipboard>
                                        }
                                        sx={{
                                          // boxShadow: "0px 2px 2px #0D0992",
                                          border: "1px solid grey",
                                          backgroundColor: "white",
                                          width: "800px",
                                          borderRadius: "10px",
                                          height: "40px",
                                          paddingTop: "6px",
                                          paddingLeft: "15px",
                                          paddingBottom: "5px",
                                          input: {
                                            color: "#646363",
                                            fontWeight: "400",
                                            "&::placeholder": {
                                              color: "#212121",
                                              opacity: 0.8,
                                            },
                                          },
                                        }}
                                      />
                                      {/* <FormHelperText sx={{ color: "red" }}>
                {campaignErrorState}
              </FormHelperText> */}
                                    </div>
                                  </div>
                                  {/* The Main Table */}
                                  <div>
                                    <TableContainer component={Paper}>
                                      <Table
                                        sx={{ minWidth: 700 }}
                                        aria-label="spanning table"
                                      >
                                        <TableHead>
                                          <TableRow
                                            sx={{
                                              "& th": {
                                                color: "rgba(96, 96, 96)",
                                              },
                                              borderTop: "none",
                                            }}
                                          >
                                            <TableCell
                                              align="center"
                                              sx={{
                                                fontSize: "18px",
                                                paddingLeft: "30px",
                                              }}
                                            >
                                              Publisher
                                            </TableCell>
                                            <TableCell
                                              width="400px"
                                              align="center"
                                              sx={{ fontSize: "18px" }}
                                            >
                                              Feeds
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              width="400px"
                                              sx={{ fontSize: "18px" }}
                                            >
                                              Schedule
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell
                                              width="400px"
                                              sx={{
                                                paddingLeft: "30px",
                                                paddingTop: "15px",
                                              }}
                                            >
                                              Publisher Name
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              width="450px"
                                              sx={{
                                                paddingLeft: "40px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div>Feed Name</div>
                                                <div
                                                  style={{
                                                    paddingRight: "160px",
                                                  }}
                                                >
                                                  Cpc
                                                </div>
                                              </div>
                                            </TableCell>

                                            <TableCell
                                              align="right"
                                              sx={{ paddingRight: "70px" }}
                                            >
                                              Time
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell
                                              sx={{
                                                paddingLeft: "30px",
                                                borderRight:
                                                  "1px solid #C5C5C5",
                                                color: "#646363",
                                                paddingTop: "0px",
                                              }}
                                            >
                                              {value.publisher_name}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                color: "#646363",
                                                borderRight:
                                                  "1px solid #C5C5C5",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  height: "70px",
                                                  overflowY: "auto",
                                                }}
                                              >
                                                {value.Feed_Campaign.map(
                                                  (data, index) => (
                                                    <div
                                                      key={index}
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          marginTop: "10px",
                                                        }}
                                                      >
                                                        {data.feed_name}
                                                      </div>
                                                      <div
                                                        style={{
                                                          marginTop: "10px",
                                                          paddingRight: "170px",
                                                        }}
                                                      >
                                                        {data.filter_cpc}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </TableCell>

                                            <TableCell
                                              align="right"
                                              width="400px"
                                              sx={{
                                                color: "#646363",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  minHeight: "70px",
                                                  overflowY: "auto",
                                                  height: "70px",
                                                  paddingRight: "50px",
                                                }}
                                              >
                                                {value.CampaignSchedule.map(
                                                  (data, index) => (
                                                    <div
                                                      key={index}
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      {data.schedule_time}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={campaignContainer.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[4, 10, 20, 30]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  );
}

export default TableBox;
