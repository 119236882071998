import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalNav from "../../Navbar/VerticalNav";

import CampaignInfoBoxes from "../../Campaigns/CampaignInfoBoxes";
import CampaignQueryBoxes from "../../Campaigns/CampaignQueryBoxes";
import CampaignTableBox from "../../Campaigns/CampaignTableBox";
import ProfileMenu from "../../Profile/ProfileMenu";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

function Campaigns({ isAuthenticated }) {
  const [liveCount, setLiveCount] = useState(0);
  const [budget, setBudget] = useState("");
  const [budgetSpent, setBudgetSpent] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <Grid container sx={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
        <Grid item md={2} sx={{ height: "100%" }}>
          <VerticalNav lineState={2} />
        </Grid>
        <Grid
          item
          md={10}
          sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}
        >
          <ProfileMenu />

          <CampaignInfoBoxes
            liveCount={liveCount}
            budget={budget}
            spent={budgetSpent}
            totalCount={totalCount}
          />
          {/* <CampaignQueryBoxes /> */}

          <CampaignTableBox
            setLiveCount={setLiveCount}
            budget={budget}
            setBudget={setBudget}
            setSpentBudget={setBudgetSpent}
            setTotalCount={setTotalCount}
          />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Campaigns);
