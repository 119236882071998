import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "../../assets/cmatrix.png";

import navstart from "../../assets/nav-start.png";
import {Link} from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';



function PublisherVerticalNav({lineState}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          marginLeft: "15px",
          alignItems: "center",
          borderRadius: "30px",
          height: "87%",
          paddingTop: "50px",
          boxShadow: 2,
          width: "95%",
          backgroundColor: "white",
          // minWidth:"260px",
          maxWidth: "300px",
        }}
      >
        <div style={{ marginBottom: "20%" }}>
          <img
            src={logo}
            alt="clickmatrix"
            style={{ width: "70%", marginLeft: "10%" }}
          />
        </div>
        <div style={{ display:"flex",flexDirection:"column",justifyContent:"center", width:"160px" }}>
          <div>
            <Link style={{ textDecoration: 'none' }} to="/publisher_table">
            <Button
              variant="text"
              sx={{
                color: "#758080",
                width: "95%",
                "&:hover": {
                  backgroundColor: "#422FCD",
                  color: "white",
                  "& .dashboard": { color: "white" },
                },
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <DashboardIcon className="dashboard" sx={{ color: "#616161" }} />
              }
            >
              Feeds
            </Button>
            {lineState==1 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
          </div>

         
        </div>

        <div style={{ marginTop: "auto" }}>
          <img
            src={navstart}
            alt="nav-icon"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </Box>
    </>
  );
}

export default PublisherVerticalNav;
