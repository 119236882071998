import React from "react";
import Box from "@mui/material/Box";
import DoughnutChart from "../Charts/DoughnutChart";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function CampaignAnalyticsBox({
  foreign = 0,
  duplicate = 0,
  expire = 0,
  botClicks = 0,
}) {
  return (
    <>
      <div style={{ height: "300px" }}>
        <h3>Invalid Clicks</h3>

        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              
            }}
          >
            <TableContainer
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "13px",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Table aria-label="simple table" size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        color: "grey",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#0077b6",
                            borderRadius: "100%",

                            marginTop: "4px",
                          }}
                        ></div>
                        <div style={{ marginLeft: "10px" }}>Foreign</div>
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "grey",
                      }}
                    >
                      {foreign}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "grey",
                      }}
                    >
                      <big
                        style={{
                          marginTop: "4px",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        {foreign == 0
                          ? 0
                          : parseInt(
                              (
                                (foreign /
                                  (foreign + duplicate + expire + botClicks)) *
                                100
                              ).toFixed(2)
                            )}
                        %
                      </big>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        color: "grey",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#90e0ef",
                            borderRadius: "100%",

                            marginTop: "4px",
                          }}
                        ></div>
                        <div style={{ marginLeft: "10px" }}>Duplicate</div>
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "grey",
                      }}
                    >
                      {duplicate}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "grey",
                      }}
                    >
                      <big
                        style={{
                          marginTop: "4px",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        {duplicate == 0
                          ? 0
                          : parseInt(
                              (
                                (duplicate /
                                  (foreign + duplicate + expire + botClicks)) *
                                100
                              ).toFixed(2)
                            )}
                        %
                      </big>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        color: "grey",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#00b4d8",
                            borderRadius: "100%",

                            marginTop: "4px",
                          }}
                        ></div>
                        <div style={{ marginLeft: "10px" }}>Expire</div>
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "grey",
                      }}
                    >
                      {expire}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "grey",
                      }}
                    >
                      <big
                        style={{
                          marginTop: "4px",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        {expire == 0
                          ? 0
                          : parseInt(
                              (
                                (expire /
                                  (foreign + duplicate + expire + botClicks)) *
                                100
                              ).toFixed(2)
                            )}
                        %
                      </big>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        color: "grey",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#03045e",
                            borderRadius: "100%",

                            marginTop: "4px",
                          }}
                        ></div>
                        <div style={{ marginLeft: "10px" }}>Bot Clicks</div>
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "grey",
                      }}
                    >
                      {botClicks}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "grey",
                      }}
                    >
                      <big
                        style={{
                          marginTop: "4px",
                          fontSize: "15px",
                          marginLeft: "20px",
                        }}
                      >
                        {botClicks == 0
                          ? 0
                          : parseInt(
                              (
                                (botClicks /
                                  (foreign + duplicate + expire + botClicks)) *
                                100
                              ).toFixed(2)
                            )}
                        %
                      </big>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div
            style={{
              height: "180px",
              width: "45%",

              marginLeft: "1%",
              marginTop: "20px",
            }}
          >
            <DoughnutChart
              foreignclick={foreign}
              duplicateclick={duplicate}
              expireclick={expire}
              botClicks={botClicks}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignAnalyticsBox;
