import React, { useState, useEffect,useLayoutEffect } from "react";
import { Box } from "@mui/system";
import feedAddImage from "../../assets/feed-add.png";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
// import countries from "./FeedCountriesData";
import { useNavigate } from "react-router-dom"
import { makeStyles } from '@mui/styles';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CustomTextField from "../CustomTextField";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { getData, addData,deleteData,editData,getDataId } from "../helpers/helpers";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const authToken =
  "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjgxODgxNDE4LCJqdGkiOiJkYTBjZTlkMmE2NjM0ZWE1OTcxNWIzZjg3OTNkMDdiZCIsInVzZXJfaWQiOjN9.m_EAPPaxp3g18XL4MH-9RKB8I06yvoM-jZkKCynPOnE";


const headers = {
    "content-type": "application/json",
    AUTHORIZATION: authToken,
  };

const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

function FeedAdd() {
  const [loader,setLoader] = useState(true);
  const [partner, setPartner] = useState("");
  const [checkpartner,setCheckPartner] = useState(null);
  const [partnerId,setPartnerId] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [partnerContainer,setPartnerContainer] = useState([]);
  const [companyContainer,setCompanyContainer] = useState([]);
  const [generalstatus,setGeneralStatus] = useState("");
  const [downloadurl, setDownloadUrl] = useState("");
  const [downloadstatus, setDownloadStatus] = useState("");
  const [credentials, setCredentials] = useState("");
  const [ftp, setFtp] = useState("");
  const [statusDWA, setStatusDwa] = useState(false);
  const [statusFTP, setStatusFTP] = useState(false);
  const [partnerModal, setPartnerModal] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);
  const [companyName,setCompanyName] = useState("");
  const [ftpUploadArea,setFtpUploadArea] = useState(0);
  const [ftpUploadUrl,setFtpUploadUrl] = useState("");
  const [credContainer,setCredContainer] = useState([]);
  const [credModal,setCredModal]  = useState(false);
  const [mainCredModal,setMainCredModal] = useState(false)
  const [container,setContainer] = useState([]);
  const [refresh,setRefresh] = useState("");

  const [downloadStatusDefault,setDownloadStatusDefault] = useState("DIRECT")

  //For Credentials Modal
  const [username,setUserName] = useState("");
  const [password,setPassword] = useState("");
  const [details,setDetails] = useState("");
  
  const [credentialUsername,setCredentialUsername] = useState("")
  const [credentialPassword,setCredentialPassword] = useState("")
  const [credentialDetails,setCredentialDetails] = useState("")

  const [editFeedsContainer,setEditFeedsContainer] = useState({});
  const [editPartnerContainer,setEditPartnerContainer] = useState({});
  const [editCompanyContainer,setEditCompanyContainer] = useState({});
  const [editCredentialContainer,setEditCredentialContainer] = useState({})
  const [editCountriesContainer,setEditCountriesContainer] = useState({})

  //Error Handling States
  const [ftpErrorState,setFtpError] = useState("");
  const [credentialErrorState,setCredentialError] = useState("")
  const [urlErrorState,setUrlError] = useState("")

  //The error for new addition modals
  const [usernameErrorState,setUsernameError] = useState("");
  const [passwordErrorState,setPasswordError] = useState("");
  const [detailsErrorState,setDetailsError] = useState("");

  const [partnerModalError,setPartnerModalError] = useState("");
  const [companyModalError,setCompanyModalError] = useState("");
  const [urlModalError,setUrlModalError] = useState("");
  const [uploadModalError,setUploadModalError] = useState("");

  const [usernameComCredError,setUsernameComCredError] = useState("");
  const [passwordComCredError,setPasswordComCredError] = useState("");
  const [detailsComCredError,setDetailsComCredError] = useState("");



  const params = useParams();
  // const [countriesAuto, setCountriesAuto] = useState(countries);

  const navigate = useNavigate()

  useEffect(() => {
    if(partnerContainer.length == 0){
      getData(setPartnerContainer, "partners");
    }
    if(companyContainer.length == 0){
      getData(setCompanyContainer,"childcompanies")
    }
    
    
    getData(setCredContainer,"credentials")
    
    // getDataId(setEditFeedsContainer,"feeds",params.id)
    const fetchData = async () => {
        await axios
        .get(
          `${process.env.REACT_APP_API_URL_DATA}feeds/${params.id}`,
          {
            // .get("http://localhost:8000/api/v1/partners", {
            headers:  {
              "content-type": "application/json",
              AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
            },
          }
        )
    .then((response) => {
      
      
      setEditPartnerContainer(response.data.partner);
      setEditCompanyContainer(response.data.company);
      setDownloadUrl(response.data.url);
      setFtp(response.data.ftp_filename);
      setEditCredentialContainer(response.data.credentials);
     
      if(response.data.downloadstatus == 'FTP'){
        setDownloadStatusDefault("FTP")
        setStatusDwa(true);
        setStatusFTP(true);
      }
      else if(response.data.downloadstatus == "DIRECT"){
        if(response.data.credentials != null){

          setDownloadStatusDefault("AUTH");
          setStatusDwa(true);
        }
        else{
          setDownloadStatusDefault("DIRECT")
          setStatusDwa(false);
        }
      }
      
      countries.map((value) => {
        if(value.code == response.data.country){
            
            setCountry(value)
        }
      })
      setLoader(false)
    //   setCountry({code:response.data.country}) 
    })
    .catch((error) => console.log(error));
    }
    
    fetchData()
   
   
    
   },[refresh]);
 

  return (
    <>
    {loader && <div style={{display:"flex",justifyContent:"center"}}><div className="wrapper">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
</div></div>}
{!loader && <>
      {/* The Modal For Partners  */}
      <Modal
        open={partnerModal}
        onClose={() => setPartnerModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <TextField
            onChange={(e) => {setPartner(e.target.value)
            if(e.target.value == ""){
              setPartnerModalError("Partner Name Is Empty")
            }else{
              setPartnerModalError("")
            }
          }}
            id="outlined-basic"
            label="Partner Name"
            variant="outlined"
            sx={{
              "& .MuiInputLabel-root": { color: "rgba(0, 0, 0, 0.5);" }, //styles the label
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  borderColor: "none",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: "#0D0992",
                },
              },
              width: "250px",
            }}
          />
          <FormHelperText sx={{color:"red",margin:0}}>{partnerModalError}</FormHelperText>

          <Button
            variant="contained"
            
            onClick={() => {
              if(partner != ""){

                addData({"name":partner},"partners").then(() => {

                  getData(setPartnerContainer, "partners",setEditPartnerContainer).then(() => {
  
                    setPartnerModal(false)
                  });
                })
              }
              else{
                setPartnerModalError("Partner Name Is Empty")
              }
              }
              
            }
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

    
      {/* The Modal for Credentials Main Add  */}
      <Modal
        open={mainCredModal}
        onClose={() => setMainCredModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              color: "#0D0992",
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            Enter Credentials
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "350px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: "10px" }}>
              <FormControl>
                <CustomTextField
                  setFunction={setCredentialUsername}
                  onChange={true}
                  setErrorFunction={setUsernameError}
                  labelName={"Username"}
                  shrink={true}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{usernameErrorState}</FormHelperText>
                </FormControl>
              </div>
              <div style={{ marginTop: "10px" }}>
              <FormControl>
                <CustomTextField
                  setFunction={setCredentialPassword}
                  labelName={"Password"}
                  onChange={true}
                  setErrorFunction={setPasswordError}
                  shrink={true}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{passwordErrorState}</FormHelperText>
                </FormControl>
              </div>
              <div style={{ marginTop: "10px" }}>
                <FormControl>
                <CustomTextField
                  setFunction={setCredentialDetails}
                  labelName={"Details"}
                  onChange={true}
                  setErrorFunction={setDetailsError}
                  shrink={true}
                />
                <FormHelperText  sx={{color:"red",margin:0}}>{detailsErrorState}</FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              let decision = true;
              const data = {
                user_name: credentialUsername,
                password: credentialPassword,
                details: credentialDetails,
              };
              if(credentialUsername == ""){
                setUsernameError("Username Is Empty");
                decision = false;
              }
              if(credentialPassword == ""){
                setPasswordError("Password Is Empty");
                decision = false;
              }
              if(credentialDetails == ""){
                setDetailsError("Details Are Empty");
                decision = false;
              }
              if(decision){

                addData(data, "credentials").then(() => {

                  getData(setCredContainer,"credentials",setEditCredentialContainer).then(() => {
  
                    setMainCredModal(false);
                  })
                });
              }
              
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>


      {/* The Modal For Companies  */}
      <Modal
        open={companyModal}
        onClose={() => setCompanyModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 370,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              color: "#0D0992",
              fontWeight: 600,
              fontSize: "20px",
              marginBottom: "35px",
              marginRight: "45px",
            }}
          >
            Enter Company Details
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "45%",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: "30px" }}>
              {/* For Input Fields  */}
              <div>
                <FormControl>
                <CustomTextField
                  setFunction={setCompanyName}
                  labelName={"Company Name"}
                  onChange={true}
                  setErrorFunction={setCompanyModalError}
                  shrink={true}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{companyModalError}</FormHelperText>
                </FormControl>
              </div>
             
              <div style={{ marginTop: "35px" }}>
                <FormControl>
                <CustomTextField
                  labelName={"Redirect URL"}
                  setFunction={setFtpUploadUrl}
                  onChange={true}
                  setErrorFunction={setUrlModalError}
                  shrink={true}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{urlModalError}</FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              let decision = true;
              var data = {
                name: companyName,
               
                redirect_url: ftpUploadUrl,
              };
              if(companyName == ""){
                setCompanyModalError("Company Name Is Empty");
                decision = false;
              }
      
              if(ftpUploadUrl == ""){
                setUrlModalError("Url Is Empty");
                decision = false;
              }
              if(decision){

                addData(data, "childcompanies").then(() => {

                  getData(setCompanyContainer,"childcompanies",setEditCompanyContainer).then(() => {
                    setCompanyModal(false);
  
                  })
                });
              }

            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "60px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

      <Box sx={{ display: "flex", height: "90%", width: "100%" }}>
        <div style={{ flex: 2 }}>
        <div style={{marginLeft:"80px"}}>
          <IconButton sx={{backgroundColor:"white",border:"1px solid #C5C5C5",borderRadius:"8px"}} onClick={() => navigate("/feeds")}>
  <ArrowBackIcon />
                </IconButton>
          </div>
          <div
            style={{
              paddingTop: "20px",
              paddingLeft: "80px",
              color: "#0D0992",
              fontWeight: 700,
              fontSize: "25px",
              marginTop: "8px",
            }}
          >
            Edit Feed
          </div>
         
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "450px",

              height: "600px",
              justifyContent: "space-around",
              marginLeft: "25%",
              marginTop: "40px",
            }}
          >
           
            
            <div
              style={{
                display: "flex",

                justifyContent: "space-between",
              }}
            >
                
              <div
                style={{
                  paddingTop: "5px",
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                Partner
              </div>
              
              <div style={{ display: "flex", marginLeft: "140px" }}>
                
             
              
                <FormControl size="small">
                    
                  <InputLabel id="partner-label">Select Partner</InputLabel>
                  <Select
                    size="small"
                    labelId="partner-label"
                    id="demo-simple-select"
                    value={editPartnerContainer }
                    onChange={(e) => {
                        setEditPartnerContainer(e.target.value)
                    //   setPartner(e.target.value.name);
                      
                    }}
                    sx={{
                      width: "250px",
                      borderRadius: "10px",
                      boxShadow: "1px 1px 1px  #0D0992",
                      "& fieldset":{border:"1px solid rgba(0, 0, 0, 0.15)"},
                      backgroundColor:"white",
                    }}
                    label="Select partner"
                  >
                    {partnerContainer.map((value) => (
                      
                    
                    <MenuItem value={value.id} key={value.id}>{value.name}</MenuItem>
                    
                    
                    ))}
                  </Select>
                </FormControl>

              
                <div style={{ paddingTop: "5px",marginLeft:"10px" }}>
                  <IconButton onClick={() => setPartnerModal(true)} sx={{
                        padding: "0px",
                        marginLeft: "20px",
                        marginTop: "2px",
                        height: "34px",
                        width: "35px",
                        border: "1px solid #C4C4C4",
                        borderRadius: "20%",
                        "&:hover": {
                          border: "1px solid #0D0992",
                          color: "#0D0992",
                          background: "white",
                        },
                      }}>
                  <AddIcon  sx={{ fontSize:"24px"}} />
                  </IconButton>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  paddingTop: "10px",
                  marginTop: "8px",
                }}
              >
                Company
              </div>
              <div style={{ display: "flex", marginLeft: "124px" }}>
             
              <FormControl size="small">
                  <InputLabel id="partner-label">Select Company</InputLabel>
                  <Select
                    size="small"
                    labelId="partner-label"
                    value ={editCompanyContainer}
                    id="demo-simple-select"
                    onChange={(e) => setEditCompanyContainer(e.target.value)}
                    sx={{
                      width: "250px",
                      borderRadius: "10px",
                      "& fieldset":{border:"1px solid rgba(0, 0, 0, 0.15)"},
                      backgroundColor:"white",
                    }}
                    
                    label="Select partner"
                  >
                    {companyContainer.map((value) => (

                    
                    <MenuItem value={value.id} key={value.id}>{value.name}</MenuItem>
                    
                    ))}
                  </Select>
                </FormControl>
               
                <div style={{ paddingTop: "5px",marginLeft:"10px"  }}>
                  <IconButton onClick={() => setCompanyModal(true)} sx={{
                        padding: "0px",
                        marginLeft: "20px",
                        marginTop: "2px",
                        height: "34px",
                        width: "35px",
                        border: "1px solid #C4C4C4",
                        borderRadius: "20%",
                        "&:hover": {
                          border: "1px solid #0D0992",
                          color: "#0D0992",
                          background: "white",
                        },
                      }}>
                    <AddIcon  sx={{ fontSize:"24px"}} />
                  </IconButton>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: "5px",
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                Country
              </div>
              <div style={{}}>
              
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: "250px", borderRadius: "10px" }}
                  size="small"
                  options={countries}
                  autoHighlight
                  value={country}
                  onChange={(event, value) => {setCountry(value)
                    }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      
                      sx={{
                        "& fieldset": {
                          borderRadius: "10px",
                          border:"1px solid rgba(0, 0, 0, 0.15)",
                     
                        },
                        backgroundColor:"white"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "country", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: "5px",
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                Download URL
              </div>
              <div style={{}}>
                <Input
                  placeholder="Enter Download URL"
                  disableUnderline={true}
                  onChange={(e) => {setDownloadUrl(e.target.value);
                  if(e.target.value == ""){
                    setUrlError("Url Field Is Empty")

                  }else{
                    setUrlError("")
                  }}}
                  value={downloadurl}
                  sx={{
                   border:"1px solid rgba(0, 0, 0, 0.15)",
                     
                    backgroundColor: "white",
                    width: "250px",
                    height: "50px",
                    borderRadius: "10px",

                    paddingLeft: "15px",
                    paddingBottom: "5px",
                    input: {
                      color: "black",
                      fontWeight: "400",
                      "&::placeholder": {
                        color: "#212121",
                        opacity: 0.6,
                      },
                    },
                  }}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{urlErrorState}</FormHelperText>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  paddingTop: "5px",
                  color: "rgba(13, 9, 146,  0.7",
                  fontWeight: 600,
                  fontSize: "18px",
                  marginTop: "8px",
                }}
              >
                Download Status
              </div>
              <div style={{}}>
                <FormControl size="small">
                  <InputLabel id="comapny-label">Download Status</InputLabel>
                  <Select
                    size="small"
                    labelId="comapny-label"
                    id="demo-simple-select"
                    value = {downloadStatusDefault}
                    onChange={(e) => {
                      
                      if (e.target.value === "AUTH") {
                        setStatusDwa(true);
                        setStatusFTP(false)
                        
                      }
                      if (e.target.value === "FTP") {
                        setStatusDwa(true);
                        setStatusFTP(true);
                      }

                      if (e.target.value === "DIRECT") {
                        setStatusDwa(false);
                        setStatusFTP(false);
                      }
                      setDownloadStatusDefault(e.target.value);
                      setGeneralStatus(e.target.value);
                      setDownloadStatus(e.target.value);
                    }}
                    sx={{
                      width: "250px",
                      borderRadius: "10px",
                      "& fieldset":{border:"1px solid rgba(0, 0, 0, 0.15)"},
                      backgroundColor:"white",
                    }}
                    label="Download Status"
                  >
                    <MenuItem value={"AUTH"}>Direct With Authentication</MenuItem>
                    <MenuItem value={"FTP"}>FTP Area</MenuItem>
                    <MenuItem value={"DIRECT"}>Direct</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            {statusDWA && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "5px",
                      color: "rgba(13, 9, 146,  0.7",
                      fontWeight: 600,
                      fontSize: "18px",
                      marginTop: "8px",
                    }}
                  >
                    Credentials
                  </div>
                  <div style={{display:"flex",marginLeft:"110px"}}>
                    <div>
                    <FormControl size="small">
                      <InputLabel id="comapny-label">Select Credential</InputLabel>
                      <Select
                        MenuProps={{ disablePortal: true }}
                        size="small"
                        value={editCredentialContainer}
                        onChange={(e) => {setEditCredentialContainer(e.target.value);
                        if(e.target.value == ""){
                          setCredentialError("Select Field ");
                        }else{
                          setCredentialError("")
                        }}}
                        labelId="comapny-label"
                        id="demo-simple-select"
                        sx={{
                          width: "250px",
                          borderRadius: "10px",
                          "& fieldset":{border:"1px solid rgba(0, 0, 0, 0.15)"},
                      backgroundColor:"white",
                          "&:hover": {
                            "&& fieldset": {
                              border: "1px solid #0D0992"
                            }
                          },
                          "&:focus": {
                            "&& fieldset": {
                              border: "1px solid red"
                            }
                          }
                        }}
                        label="Select Credentials"
                      >
                        {credContainer.map((value) => (
                            <MenuItem value={value.id} key={value.id}>{value.details}</MenuItem>
                        ))}
                        
                        
                      </Select>
                      <FormHelperText sx={{color:"red",margin:0}}>{credentialErrorState}</FormHelperText>
                    </FormControl>
                    </div>
                    <div style={{ paddingTop: "5px",marginLeft:"10px" }}>
                  <IconButton onClick={() => setMainCredModal(true)} sx={{
                        padding: "0px",
                        marginLeft: "20px",
                        marginTop: "2px",
                        height: "34px",
                        width: "35px",
                        border: "1px solid #C4C4C4",
                        borderRadius: "20%",
                        "&:hover": {
                          border: "1px solid #0D0992",
                          color: "#0D0992",
                          background: "white",
                        },
                      }}>
                  <AddIcon  sx={{fontSize:"24px"}} />
                  </IconButton>
                </div>
                  </div>
                </div>
                {statusFTP && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "5px",
                          color: "rgba(13, 9, 146,  0.7",
                          fontWeight: 600,
                          fontSize: "18px",
                          marginTop: "8px",
                        }}
                      >
                        FTP Area Filename
                      </div>
                      <div style={{}}>
                        <Input
                          placeholder="Enter FTP Filename"
                          disableUnderline={true}
                          onChange={(e) => {setFtp(e.target.value)
                          if(e.target.value == ""){
                            setFtpError("FTP Area Is Empty")
                          }else{
                            setFtpError("")
                          }}}
                          value={ftp}
                          sx={{
                            border:"1px solid rgba(0, 0, 0, 0.15)",
                            backgroundColor: "white",
                            width: "250px",
                            height: "50px",
                            borderRadius: "10px",
                           

                            paddingLeft: "15px",
                            paddingBottom: "5px",
                            input: {
                              color: "black",
                              fontWeight: "400",
                              "&::placeholder": {
                                color: "#212121",
                                opacity: 0.8,
                              },
                            },
                          }}
                        />
                        <FormHelperText sx={{color:"red",margin:0}}>{ftpErrorState}</FormHelperText>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>

                   

          
          <div style={{ paddingLeft: "25%", paddingTop: "50px" }}>
            <Button
              variant="contained"
              onClick={async () => {
                let decision = true
                if(downloadStatusDefault == 'FTP'){
                    if(ftp == ""){
                      setFtpError("Ftp Field Cant Be Empty")
                      decision = false
                    }
                    if(editCredentialContainer == "" || editCredentialContainer == null){
                      setCredentialError("Credentials Should Be Selected")
                      decision = false
                    }
                }
                if(downloadStatusDefault == "AUTH"){
                  if(editCredentialContainer == "" || editCredentialContainer == null){
                    setCredentialError("Credentials Should Be Selected")
                    decision = false
                  }
                }
                if(downloadurl == ""){
                  setUrlError("Url Field Is Empty");
                  decision = false;
                }
                

                const data = {
                  company:editCompanyContainer,
                 
                  country:country.code,
                  url:downloadurl,
                  downloadstatus:downloadStatusDefault,
                  credentials:editCredentialContainer,
                  ftp_filename:ftp,
                  partner:editPartnerContainer

                };
                
                if(decision){
                  let c = await editData(data,"feeds",params.id)
                  if(c !== null){
                    navigate("/feeds")
                  }
                }
                
                
                
                
                
                
              }}
              sx={{
                backgroundColor: "#0D0992",
                width: "140px",
                borderRadius: "8px",
              }}
            >
              Update
            </Button>
          </div>
        </div>
        {/* </div> */}
        <div style={{ flex: 1 }}>
          {/* <div
            style={{
              height: "100%",
              marginTop: "90px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <img src={feedAddImage} style={{ width: "100%" }} alt="feedAdd" />
          </div> */}
        </div>
      </Box>
      </>}
    </>
  );
}

export default FeedAdd;
