import React from "react";
import { Doughnut } from "react-chartjs-2";

function DoughnutHalf({primaryColor,secondaryColor,title,clicks,innerClick,innerTitle,datavalue,labelvalue = [],type=null}) {
  const data = {
    datasets: [
      {
        label: "",
        data: datavalue,
        backgroundColor: [primaryColor, secondaryColor],
        borderColor: [
          //     '#FD2254',
          //     '#00B7FE',
          //     '#D0D2DA',
        //   "black",
        //   "black",
        //   "black",
        //   "black",
        ],
        borderWidth: 1,

        hoverOffset: 2,
      },
    ],
    labels:labelvalue
  };
  const options = {
    elements: {
      arc: {
        borderJoinStyle: "miter",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    rotation: 3.1,
    tooltips: { enabled: true },
    legend: {
      display: false, // <- the important part
    },
    circumference: 3.2,
  };
  return (
    <>
    
      <div style={{ paddingTop:"35px"}}>
        <div style={{position: "absolute",maxWidth:"350px"}}>
          <Doughnut options={options} data={data} />
          
        </div>

        <div style={{ position: "relative",left:"70px",top:"70px" ,width:"150px",display:"flex",flexDirection:"column",alignItems:"center"}}>
          <big style={{fontWeight:"bolder",color:"#121212"}}>{innerClick} </big>
          <small style={{color:"#121212"}}>{innerTitle}</small>
          
        </div>
      </div>

       <div
        style={{
          display: "flex",
          
          width: "300px",
          justifyContent: "center",
          marginTop: "140px",
         
            
        }}
      >
        
            <div style={{width:"12px",height:"12px",borderRadius:"100%",backgroundColor:primaryColor,marginTop:"5px",marginRight:"10px"}}>

            </div>
        
        <div style={{ marginRight: "30px"}}>
            
          <small style={{paddingTop:"0px",color:"#616F81"}}>{title}</small>
        </div>
        <div>
          
            {type == "budget" ? <strong>{clicks.toFixed(2)}</strong> : <strong>{clicks}</strong>}
          
        </div>
      </div> 
      
    </>
  );
}

export default DoughnutHalf;
