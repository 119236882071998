import React,{useState} from "react";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

import VerticalNav from "../../Navbar/VerticalNav";
import FeedMenu from "../../Feeds/FeedMenu";
import TableBox from "../../Feeds/FeedTableBox";

import ProfileMenu from "../../Profile/ProfileMenu";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

function Main({isAuthenticated}) {
  
  
  if(!isAuthenticated){
    
    return <Navigate to="/login" />;
  }

  
   
  return (
    
    <>
      
        
        <Grid
          container
         
          sx={{  height: "100vh",backgroundColor:"#F7F8FA" }}

        >
          <Grid item md={2}  sx={{height:"100%"}}>
            <VerticalNav lineState={3} />
          </Grid>
          <Grid item md={10}   sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}>
            <ProfileMenu />
            {/* <FeedAdd /> */}
             {/* <FeedMenu /> */}
            <TableBox /> 
          </Grid>
        </Grid>
      
      
      
      
      
      
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Main);
