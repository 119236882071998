import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalNav from "../../Navbar/VerticalNav";
import CampaignAddIndex from "../../Campaigns/CampaignAddIndex";
import ProfileMenu from "../../Profile/ProfileMenu";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";


import "./scroll.css";

function CampaignAdd({ isAuthenticated }) {
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <Grid container sx={{ height: "100vh" , backgroundColor: "#F7F8FA"}}>
        <Grid item md={2} sx={{ height: "100%" }}>
          <VerticalNav lineState={2} />
        </Grid>
        <Grid
          item
          md={10}
          sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}
        >
          <ProfileMenu />
          <CampaignAddIndex />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(CampaignAdd);
