import React,{useState,useEffect} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PublisherVerticalNav from "../../Navbar/PublisherVerticalNav";
import PublisherAnalyticsBox from "../../Publisher App/PublisherAnalyticsBox";
import PublisherAnalyticsProgressBox from "../../Publisher App/PublisherAnalyticsProgressBox";
import DoughnutHalf from "../../Publisher App/DoughnutHalf";
import PublisherProfile from "../../Publisher App/PublisherProfile";
import Chart from "../../Publisher App/Chart";
import { useParams } from "react-router-dom";
import { getDataId } from "../../helpers/helpers";

function CampaignAnalytics() {
  const params = useParams();
  const [publisher_stats,setPublisherStats] = useState([]);

  useEffect( () => {
    getDataId(setPublisherStats,"campaign_stats",params.id)
  },[])
  
  
  return (
    <>
      <Box sx={{ position: "absolute", width: "100%" }}>
        <Grid container sx={{ height: "100vh" }}>
          <Grid item md={2} sx={{ marginTop: "30px" }}>
            <PublisherVerticalNav />
          </Grid>
          <Grid item md={10} sx={{ paddingLeft: 2 }}>
          <PublisherProfile />
           
            <div style={{ display: "flex",marginLeft:"6%",marginTop:"5%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "59%",
                  boxShadow:"rgba(0, 0, 0, 0.06) 0px 5px 4px 0px"
                }}
              >
                <div>
                  <DoughnutHalf
                    primaryColor={"#0878FF"}
                    secondaryColor={"#696969"}
                    title={"Budget Spent"}
                    clicks={publisher_stats.valid_clicks*parseFloat(publisher_stats.global_cpc)}
                    labelvalue={["Budget Spent","Remaining Budget"]}
                    datavalue={[publisher_stats.valid_clicks*parseFloat(publisher_stats.global_cpc),publisher_stats.total_budget-(publisher_stats.valid_clicks*parseFloat(publisher_stats.global_cpc))]}
                    innerClick={publisher_stats.total_budget}
                    innerTitle={"Total Budget"}
                    type="budget"
                  />
                </div>
                <div>
                  <DoughnutHalf
                    primaryColor={"#EE2B1F"}
                    secondaryColor={"#888888"}
                    title={"Valid Clicks"}
                    clicks={publisher_stats.valid_clicks}
                    innerClick={publisher_stats.total_clicks}
                    labelvalue={["Valid Clicks","Invalid Clicks"]}
                    datavalue={[publisher_stats.valid_clicks,publisher_stats.total_clicks-publisher_stats.valid_clicks]}
                    innerTitle={"Total Clicks"}
                  />
                </div>
              </div>
              <div style={{boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",paddingLeft:"15px"}}>
              <PublisherAnalyticsBox foreign={publisher_stats.foreign_clicks} duplicate={publisher_stats.duplicate_clicks} expire={publisher_stats.expired_clicks} botClicks={publisher_stats.bot_clicks} /> 
                {/* <PublisherAnalyticsProgressBox /> */}
              </div>
            </div>
            <div style={{display:"flex",justifyContent:"space-around",marginTop:"7%"}}>
                <div style={{width:"55%",boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",paddingLeft:"15px",paddingTop:"15px",paddingBottom:"15px",height:"250px"}}>
                  <Chart campaignId={params.id} />
                </div>
                <div style={{boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",paddingLeft:"15px"}}>
                  {/* <PublisherAnalyticsBox foreign={publisher_stats.foreign_clicks} duplicate={publisher_stats.duplicate_clicks} expire={publisher_stats.expired_clicks} botClicks={publisher_stats.bot_clicks} /> */}
                </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}



export default CampaignAnalytics;
