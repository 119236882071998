import Main from "./Components/pages/Feed Pages/Main";
import { Routes, Route } from "react-router-dom";
import FeedCreate from "./Components/pages/Feed Pages/FeedCreate";
import FeedEdit from "./Components/pages/Feed Pages/FeedEdit";
import Campaigns from "./Components/pages/Campaign Pages/Campaigns";
import Partners from "./Components/pages/Partner Pages/Partners";
import Home from "./Components/pages/Home";
import Home2 from "./Components/pages/Home2";
import Login from "./Components/pages/Login";
import Tagmap from "./Components/pages/Tagmap Pages/Tagmap";
import Dashboard from "./Components/pages/Dashboard Pages/Dashboard";
import Profile from "./Components/pages/Profile Page/Profile";
import Publisher from "./Components/pages/Publisher Pages/Publisher";
import Credentials from "./Components/pages/Credential Pages/Credentials";
import CampaignAdd from "./Components/pages/Campaign Pages/CampaignAdd";
import Companies from "./Components/pages/Company Pages/Companies";
import CampaignAnalytics from "./Components/pages/Campaign Pages/CampaignAnalytics";
import CampaignEdit from "./Components/pages/Campaign Pages/CampaignEdit";
import PublisherAnalytics from "./Components/pages/Publisher App/PublisherAnalytics";
import Layout from "./Components/Layout";
import { connect } from "react-redux";
import NotFound from "./NotFound";
import { useState } from "react";
import PublisherPage from "./Components/pages/Publisher App/PublisherPage";
import FtpConfig from "./Components/pages/Ftp Config Pages/FtpConfig";
import Notification from "./Components/pages/Notification Pages/Notification";
import FeedReport from "./Components/pages/Feed Pages/FeedReport";

function App({ isAuthenticated, user }) {
  var is_publisher = null;
  if (user) {
    is_publisher = user[0].is_publisher;
  }

  return (
    <div className="App">
      
      
          <Layout>
            <Routes>
              
              {isAuthenticated === true && is_publisher === false && (
                <>
                  <Route path="" element={<Home />} />
                  <Route path="/feeds" element={<Main />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/feeds/new" element={<FeedCreate />} />
                  <Route path="/feeds/reports" element={<FeedReport />} />
                  <Route path="/feed/edit/:id" element={<FeedEdit />} />
                  <Route path="/campaign/edit/:id" element={<CampaignEdit />} />
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/campaign/new" element={<CampaignAdd />} />
                  <Route
                    path="/campaign-analytics/:id"
                    element={<CampaignAnalytics />}
                  />
                  <Route path="/partners" element={<Partners />} />
                  <Route path="/tagmap" element={<Tagmap />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/publisher" element={<Publisher />} />
                  <Route path="/credentials" element={<Credentials />} />
                  <Route path="/companies" element={<Companies />} />
                  <Route path="/publisher_app" element={<PublisherPage />} />
                  <Route path="/ftp_config" element={<FtpConfig />} />
                  <Route path="/notifications" element={<Notification />} />
                  <Route path="*" element={<NotFound />} />
                </>
              )}
              {isAuthenticated === true && is_publisher === true && (
                <>
                  <Route path="/publisher_table" element={<PublisherPage />} />
                  <Route
                    path="/publisher_analytics/:id"
                    element={<PublisherAnalytics />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="" element={<Home />} />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/notifications" element={<Notification />} />
                </>
              )}

              {!isAuthenticated && (
                <>
                  <Route path="" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  
                </>
              )}
            </Routes>
          </Layout>
        
      
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(App);
