import React,{useState,useEffect} from 'react'
import DashboardIndex from '../../Dashboard/DashboardIndex'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalNav from "../../Navbar/VerticalNav";
import DashboardInfoBoxes from '../../Dashboard/DashboardInfoBoxes';

import ProfileMenu from '../../Profile/ProfileMenu';
import axios from 'axios';

const authToken = `JWT ${localStorage.getItem("access")}`;
// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;


function Dashboard() {
    const [forselect,setSelect] = useState("Hello")
    const [dashboardContainer,setDashboardContainer] = useState([]);
    const [totalCampaign,setTotalCampaign] = useState("");
    const [budgetSpent,setBudgetSpent] = useState("");
    const [totalBudget,setTotalBudget] = useState("");
    const [liveCampaign,setLiveCampaign] = useState("");

    useEffect(() => {
      const getData = async () => {
  
        await axios
          .get(`${apiLink}dashboard_stats`, {
            // .get("http://localhost:8000/api/v1/partners", {
            headers: {
              "content-type": "application/json",
              AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
            },
          })
          .then((response) => {
            
            
            setTotalCampaign(response.data.total_campaigns);
            setLiveCampaign(response.data.live_campaigns)
            setBudgetSpent(response.data.budget_spent);
            setTotalBudget(response.data.total_budget);
          })
          .catch((error) => console.log(error));
      };
      getData()
     
      
    },[])

  return (
    <>
    
    <Grid container sx={{ height: "100vh" ,backgroundColor:"#F7F8FA"}}>
      <Grid item md={2} sx={{ height:"100%" }}>
        <VerticalNav lineState={1}/>
      </Grid>
      <Grid item md={10} sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}>
        <ProfileMenu />
        {/* <div style={{display:"flex",justifyContent:"flex-end",width:"97%"}}>
       
      
                <div style={{marginTop:"10px"}}>
                <FormControl fullWidth>
                <InputLabel id="comapny-label">Select Country </InputLabel>
                <Select
                    size="medium"
                  labelId="comapny-label"
                  id="demo-simple-select"
                  
                  sx={{width: "300px",
                  borderRadius: "10px",
                  }}
                  label="Select Country"
                  
                  
                  
                  

                  >
                    
                  <MenuItem value={"Country 1"}>USA</MenuItem>
                  <MenuItem value={"Country 2"}>UK</MenuItem>
                  <MenuItem value={"Country 3"}>CANADA</MenuItem>
                </Select>
                </FormControl>
                </div>
        </div> */}
        <DashboardInfoBoxes totalCampaign={totalCampaign} totalBudget={totalBudget} liveCampaign={liveCampaign} />
        <DashboardIndex budgetSpent={budgetSpent} totalBudget={totalBudget} />
        {/* <FeedMenu /> */}
        {/* <TableBox />  */}
      </Grid>
    </Grid>
 

 </>
  )
}

export default Dashboard