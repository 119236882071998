import React,{useState,useEffect} from 'react'
import clickprofile from "../../assets/cmatrix-profile-default.jpg";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import {logout} from '../actions/auth';
import {Link} from "react-router-dom";
import Badge from '@mui/material/Badge';
import axios from "axios";

const authToken = `JWT ${localStorage.getItem("access")}`;
// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;


function ProfileMenu({logout}) {
   const [anchorEl, setAnchorEl] = useState(null);
   const [notificationCount,setNotificationCount] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const getNotification = async () => {
      let count = 0
    await axios
    .get(`${apiLink}notifications`, {
      
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
    })
    .then((response) => {
      
        response.data.map((value) => {
          if(!value.is_read){
            count += 1
          }
        });
        setNotificationCount(count)
      
    })
    .catch((error) => console.log(error));
  }
  getNotification()
  },[])
  return (
     <>
        <div style={{display:"flex",justifyContent:"flex-end",paddingTop:"10px",paddingRight:"50px"}}>
          <div style={{paddingRight:"20px",paddingTop:"20px"}}>
          {/* <Link to="/notifications"><Badge badgeContent={notificationCount} color="primary"><MenuItem sx={{color:"black",fontSize:"15px",fontWeight:"bold",borderRadius:"7px"}}>Notification</MenuItem></Badge></Link> */}
          <Link to="/notifications"><MenuItem sx={{color:"black",fontSize:"15px",fontWeight:"bold",borderRadius:"7px"}}>Notification</MenuItem></Link>
        </div>

        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div><img src={clickprofile} alt="user-is" style={{width:"60px",height:"60px",borderRadius:"50%"}} /></div>
        
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
         vertical: 'top',
         horizontal: 'left',
       }}
      >
        <Link to="/profile"><MenuItem >Settings</MenuItem></Link>
        
        <Link to="/login"><MenuItem onClick={logout}>Logout</MenuItem></Link>
        
      </Menu>
      </div>
     </>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})



export default connect(mapStateToProps,{logout})(ProfileMenu)