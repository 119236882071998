import React,{useEffect,useState} from 'react';
import axios from "axios";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
import { Bar } from 'react-chartjs-2';



const authToken = `JWT ${localStorage.getItem("access")}`;
// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};

const apiLink = process.env.REACT_APP_API_URL_DATA;


function BarChart({campaignId}) {
  const [labelContainer, setLabelContainer] = useState([]);
  const [foreignContainer,setForeignContainer] = useState([]);
  const [validContainer,setValidContainer] = useState([]);
  const [duplicateContainer,setDuplicateContainer] = useState([]);
  const [expiredContainer,setExpiredContainer] = useState([]);
  const [botContainer,setBotContainer] = useState([]);
  const [totalContainer,setTotalContainer] = useState([]);
  const [partnerContainer,setPartnerContainer] = useState([]);
  const [display,setDisplay] = useState(false)
  useEffect(() => {
    const getData = async () => {
  
      await axios
        .get(`${apiLink}campaign_clicks/${campaignId}`, {
          // .get("http://localhost:8000/api/v1/partners", {
          headers: {
            "content-type": "application/json",
            AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          //   setData(response.data);
          response.data.map((value) => {
            setLabelContainer((arr) => [...arr,value.date]);
            setForeignContainer((arr) => [...arr,value.foreign_clicks]);
            setExpiredContainer((arr) => [...arr,value.expired_clicks]);
            setDuplicateContainer((arr) => [...arr,value.duplicate_clicks]);
            setBotContainer((arr) => [...arr,value.bot_clicks]);
            setTotalContainer((arr) => [...arr,value.total_clicks]);
            setPartnerContainer((arr) => [...arr,value.partner_revenue]);
            setValidContainer((arr) => [...arr,value.valid_clicks])
            
            
          });
          
          
          
        })
        .catch((error) => console.log(error));
    };
    getData()
   
    

    
  }, []);

  
  
  var dataconfig = {
    labels: labelContainer,
    // labels: ["0",10],

    datasets: [
      {
          type: 'line',
          label: 'Revenue',
          data: partnerContainer,
          fill: false,
          borderColor:"#fbb13c",
          backgroundColor: '#fbb13c'
      },
      {
        label: 'Bot Clicks',
        data: botContainer,
        fill:true,
        backgroundColor: '#03045e',
      },
      {
        label: 'Foreign Clicks',
        data: foreignContainer,
        fill:true,
        backgroundColor: '#0077b6',
      },
      {
        label: 'Expire Clicks',
        data: expiredContainer,
        fill:true,
        backgroundColor: '#00b4d8',
      },
      {
        label: 'Duplicate Clicks',
        data: duplicateContainer,
        fill:true,
        backgroundColor: '#90e0ef',
      },
      {
        label: 'Total Clicks',
        data: totalContainer,
        fill:true,
        backgroundColor: '#caf0f8',
      },
      {
        label: 'Valid Clicks',
        data: validContainer,
        fill:true,
        backgroundColor: '#73d2de',
      },
      
      // {
      //   label: 'Partner Revenue',
      //   data: partnerContainer,
      //   fill:true,
      //   backgroundColor: 'rgb(153, 162, 125)',
      // },
      // {
      //   label: 'Bot Clicks',
      //   data: [20,30,40],
      //   backgroundColor: 'rgb(255, 99, 132)',
      // },
      // {
      //   label: 'Foreign Clicks',
      //   data: [100,200,300],
      //   backgroundColor: 'rgb(75, 192, 192)',
      // },
      // {
      //   label: 'Expire Clicks',
      //   data: [66,78,100],
      //   backgroundColor: 'rgb(53, 162, 235)',
      // },
      // {
      //   label: 'Duplicate Clicks',
      //   data: [210,250,300],
      //   backgroundColor: 'rgb(53, 12, 235)',
      // },
      // {
      //   label: 'Total Clicks',
      //   data: [300,200,100],
      //   backgroundColor: 'rgb(53, 162, 25)',
      // },
      // {
      //   label: 'Valid Clicks',
      //   data: [120,320,666],
      //   backgroundColor: 'rgb(53, 62, 25)',
      // },
      // {
      //   label: 'Bot Clicks',
      //   data: [33,43,54],
      //   backgroundColor: 'rgb(253, 162, 25)',
      // },
      // {
      //   label: 'Partner Revenue',
      //   data: [40,50,100],
      //   backgroundColor: 'rgb(153, 162, 125)',
      // },
      
    ],
  };

  var options = {
    plugins: {
      title: {
        display: true,
        text: 'Campaign Bar',
      },
      
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode:"index",
      intersect: false,
      callbacks: {
          // labelColor: function(tooltipItem,data) {
          //     console.log("Inside label color the data is :",data)
          //     let plex = []
          //     data.datasets.map((value)=> {
              
                  
          //         plex.push(value.backgroundColor);
          //     })
          //     return {
          //         borderColor: plex,
          //         backgroundColor: 'rgb(255, 0, 0)',
          //         borderWidth: 2,
          //         borderDash: [2, 2],
          //         borderRadius: 2,
          //     };
          // },
          // labelColor: function(tooltipItem, chart) {
          //     var dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
          //     return {
          //         backgroundColor : dataset.backgroundColor
          //     }
          // },
        title: function (tooltipItems,data) {
          
          return data.labels[tooltipItems[0].index];
        },
        label:function (tooltipItem,data) {
          
          // return data.datasets[0]
          // return 'id: ' + tooltipItems.yLabel+' | '+' age: '+tooltipItems.xLabel
          let plex = []
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label;
          // data.datasets.map((value)=> {
              
          //     let v = value.data[tooltipItem.index];
          //     let l = value.label;
          //     plex.push(l + "------" + v);
          // })
          return (label + " ------ "+ value);
        },
      }
    },
    
    
    legend: {
     
      display: false, // <- the important part
    },
    scales: {
      xAxes: [{
          stacked: true,
          barPercentage: 0.4,
          ticks: {
            beginAtZero: true,
            min: 0
          }  
         
          
      }],
      yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            min: 0
          }  
      }],
     
  }
  };
  
  

  return <div style={{width:"90%",height:"300px"}}><Bar data={dataconfig} options={options} /></div>;
}

export default BarChart;




