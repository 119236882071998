import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "../../assets/cmatrix.png";
import HomeIcon from "@mui/icons-material/Home";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PersonIcon from "@mui/icons-material/Person";
import CampaignIcon from "@mui/icons-material/Campaign";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import navstart from "../../assets/nav-start.png";
import {Link} from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedIcon from '@mui/icons-material/Feed';
import LockIcon from '@mui/icons-material/Lock';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PublishIcon from '@mui/icons-material/Publish';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';


function VerticalNav({lineState}) {
  return (
    <div style={{marginTop:"5%", height:"95%"}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          marginLeft: "15px",
          alignItems: "center",
          borderRadius: "13px",
          border:"0.5px solid #C5C5C5",
          backgroundColor:"white",
          height:"100%",
          paddingTop:"4%",
          
          
          width: "95%",
          // minWidth:"260px",
          maxWidth: "300px",
        }}
      >
        <div style={{ marginBottom: "20%" }}>
          <img
            src={logo}
            alt="clickmatrix"
            style={{ width: "70%", marginLeft: "10%" }}
          />
        </div>
        <div style={{ display:"flex",flexDirection:"column",justifyContent:"center", width:"160px" }}>
          <div>
            <Link style={{ textDecoration: 'none' }} to="/dashboard">
            <Button
              variant="text"
              sx={{
                color: "#A6A5A5",
                width: "95%",
                "&:hover": {
                  backgroundColor: "#422FCD",
                  color: "white",
                  borderRadius:"7px",
                  "& .dashboard": { color: "white" },
                },
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <DashboardIcon className="dashboard" sx={{ color: "#616161" }} />
              }
            >
              Dashboard
            </Button>
            {lineState==1 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
          </div>

          <div>
            <Link style={{ textDecoration: 'none' }} to="/campaigns">
            <Button
              variant="text"
              sx={{
                color: "#A6A5A5",
                width: "95%",
                "&:hover": {
                  borderRadius:"7px",
                  backgroundColor: "#422FCD",
                  color: "white",
                  "& .campaign": { color: "white" },
                },
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <CampaignIcon
                  className="campaign"
                  sx={{ color: "#616161" }}
                />
              }
            >
              Campaigns
            </Button>
            {lineState==2 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
          </div>

          <div>
            <Link style={{ textDecoration: 'none' }} to="/feeds">
            <Button
              variant="text"
              sx={{
                color: "#A6A5A5",
                width: "95%",
                "&:hover": {
                  borderRadius:"7px",
                  backgroundColor: "#422FCD",
                  color: "white",
                  "& .feed": { color: "white" },
                },
                fontSize: "16px",
               
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <FeedIcon className="feed" sx={{ color: "#616161" }} />
              }
            >
              Feeds
            </Button>
            {lineState==3 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            
            </Link>
          </div>

       

          <div>
            <Link style={{ textDecoration: 'none' }} to="/tagmap">
            <Button
              variant="text"
              sx={{
                
                color: "#A6A5A5",
                
                width: "95%",
                "&:hover": {
                  backgroundColor: "#422FCD",
                  borderRadius:"7px",
                  color: "white",
                  "& .xml": { color: "white" },
                },
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <SettingsInputComponentIcon
                  className="xml"
                  sx={{ color: "#616161" }}
                />
              }
            >
              XML Config
            </Button>
            {lineState==4 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
            
          </div>

          <div>
            <Link style={{ textDecoration: 'none' }} to="/credentials">
            <Button
              variant="text"
              sx={{
                color: "#A6A5A5",
                width: "95%",
                "&:hover": {
                  backgroundColor: "#422FCD",
                  borderRadius:"7px",
                  color: "white",
                  "& .credential": { color: "white" },
                },
                fontSize: "16px",
               
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <LockIcon
                  className="credential"
                  sx={{ color: "#616161" }}
                />
              }
            >
              Credentials
            </Button>
            {lineState==5 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
          </div>

          <div>
            <Link style={{ textDecoration: 'none' }} to="/ftp_config">
            <Button
              variant="text"
              sx={{
                color: "#A6A5A5",
                width: "95%",
                "&:hover": {
                  backgroundColor: "#422FCD",
                  borderRadius:"7px",
                  color: "white",
                  "& .credential": { color: "white" },
                },
                fontSize: "16px",
               
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <LockIcon
                  className="credential"
                  sx={{ color: "#616161" }}
                />
              }
            >
              Ftp Config
            </Button>
            {lineState==9 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
          </div>

          <div>
            <Link style={{ textDecoration: 'none' }} to="/partners">
            <Button
              variant="text"
              sx={{
                color: "#A6A5A5",
                width: "95%",
                "&:hover": {
                  backgroundColor: "#422FCD",
                  borderRadius:"7px",
                  color: "white",
                  "& .partner": { color: "white" },
                },
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <HandshakeIcon
                  className="partner"
                  sx={{ color: "#616161" }}
                />
              }
            >
              Partners
            </Button>
            {lineState==6 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
          </div>

          <div>
            <Link style={{ textDecoration: 'none' }} to="/publisher">
            <Button
              variant="text"
              sx={{
                color: "#A6A5A5",
                width: "95%",
                "&:hover": {
                  borderRadius:"7px",
                  backgroundColor: "#422FCD",
                  color: "white",
                  "& .publisher": { color: "white" },
                },
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <PublishIcon
                  className="publisher"
                  sx={{ color: "#616161" }}
                />
              }
            >
              Publisher
            </Button>
            {lineState==7 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
          </div>

          <div>
            <Link style={{ textDecoration: 'none' }} to="/companies">
            <Button
              variant="text"
              sx={{
                color: "#A6A5A5",
                width: "95%",
                "&:hover": {
                  borderRadius:"7px",
                  backgroundColor: "#422FCD",
                  color: "white",
                  "& .company": { color: "white" },
                },
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <ApartmentIcon
                  className="company"
                  sx={{ color: "#616161" }}
                />
              }
            >
              Companies
            </Button>
            {lineState==8 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
}
            </Link>
          </div>
        </div>

        <div style={{ marginTop: "auto" }}>
          <img
            src={navstart}
            alt="nav-icon"
            style={{ width: "100%", height: "100%",borderRadius: "13px", }}
          />
        </div>
      </Box>
    </div>
  );
}

export default VerticalNav;
